// @import "style.scss";


.paginationBttns {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: end;
  list-style: none;
  // position: absolute;
  // bottom: 15px;
  // right: 20px;
}

.paginationBttns a {
  padding: 6px 10px 6px 10px;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid var(--darkBackgroundcolor4675F1);
  color: var(--darkBackgroundcolor4675F1) !important;
  font-size: 13px;
  cursor: pointer;
  text-decoration: none;
}

.paginationBttns a:hover {
  color: white !important;
  background-color: var(--darkBackgroundcolor4675F1);
}

.paginationActive a {
  color: white !important;
  background-color: var(--darkBackgroundcolor4675F1)
}

@media all and (max-width: 768px) {
  .paginationBttns {
    li {
      margin-bottom: 20px !important;
    }
  }
}

