* {
  font-family: "Noto Sans", sans-serif !important;
  font-family: "Poppins", sans-serif !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  // display: none;
}

::-webkit-scrollbar-thumb {
  background: #4675f1;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: white;
  border-radius: 10px;
}

.loader-wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.capital {
  text-transform: capitalize
}

@import url(./globalStyles.scss);
@import url(./variable.scss);
@import url(./sidebar.scss);
@import url(./header.scss);
@import url(./auth.scss);
@import url(./dashboard.scss);
@import url(./doctors.scss);
@import url(./reports.scss);
@import url(./pharmacy.scss);
@import url(./vetPet.scss);
@import url(./aameerResponsive.scss);
@import url(./usamaResponsive.scss);
@import url(./backdrop.scss);
@import url(./subscription.scss);
@import url(./pagination.scss);
@import url(./backdrop.scss);

.error-msg {
  font-size: .875em;
  color: #dc3545;
  margin-top: 5px;
}