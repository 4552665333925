.authMain {
  padding: 20px;

  .otpbtn {
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--darkBlueColor4675F1);
    padding: 3px 16px;
    width: 110px;
    border-radius: 20px;
    border: none;
    outline: none;
    background: #f2f5f8;
  }

  .logoImg {
    height: 80px;
    width: 80px;
  }

  .signinForm {

    margin: 130px;

    .signInTxt {
      font-size: 30px;
      margin-bottom: 10px;
    }

    .forgetLnk {
      margin-bottom: 50px;

      a {
        color: var(--darkBlueColor4675F1);
        text-decoration: none;
        font-weight: 600;
      }
    }

    .inputBox {
      position: relative;
      margin-bottom: 40px;

      .emailLabel {
        font-size: 13px;
        color: var(--lightGreyColor999999);
        margin-bottom: 5px;
      }

      .mailImg {
        position: absolute;
        top: 32px;
        left: 0px;
      }

      .eyeImg {
        cursor: pointer;
        position: absolute;
        top: 38px;
        right: 0px;
      }

      .formInput {
        border: none;
        border-radius: 0px !important;
        border-bottom: 1px solid var(--lightGreyColor999999);
        padding-left: 26px;

        &:focus {
          box-shadow: none !important;
          border-bottom: 2px solid var(--darkBlackColor000);
        }

        &::placeholder {
          color: var(--darkBlueColor4675F1) !important;
          font-size: 16px;
        }
      }

      .belowChecks {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .form-check {
          padding-left: 1em;

          label {
            color: var(--darkBlueColor4675F1);
            font-size: 12px;
          }
        }

        .checkLbl {
          color: var(--darkBlueColor4675F1);
          font-size: 12px;
        }

        .form-check-input {
          border-color: var(--darkBlueColor4675F1);

          &:checked {
            background-color: var(--darkBackgroundcolor4675F1) !important;
          }

          &:focus {
            box-shadow: none !important;
          }
        }

        .forgetPass {
          .forgetLab {
            color: var(--lightGreytxtADADAD);
            text-decoration: none;
            font-size: 12px;
          }
        }
      }
    }

    .login {

      // width: 100%;
      button {
        width: 100%;
        border: none;
        color: var(--whiteTxtColorfff);
        height: 52px;
        background-color: var(--darkBackgroundcolor4675F1);
        border-radius: 30px;
      }
    }
  }

  .landingImg {
    background-color: var(--darkBackgroundcolor4675F1) !important;
    min-height: calc(100vh - 40px);
    border-radius: 13px;
    padding: 15px;

    .mobileNumber {
      text-align: end;

      .callNumber {
        color: var(--whiteTxtColorfff);
        font-size: 13px;
      }
    }

    .insiderIcon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        object-fit: cover;
        margin-bottom: 15px;
        height: auto;
      }
    }

    .landingTxt {
      display: flex;
      justify-content: center !important;
      margin-bottom: 20px;

      h1 {
        font-size: 34px;
        color: var(--whiteTxtColorfff);
        font-weight: 600;
      }

      p {
        font-size: 17px;
        color: var(--whiteTxtColorfff);
      }
    }
  }
}