.lightMode {
    // BACKGROUND-COLORS VARIABLES
    --darkBackgroundcolor4675F1: #4675F1;
    --whiteBackGroundColorfff: #fff;
    --lightBodyBackgroundEEF3FC: #EEF3FC;
    --lightBlueBackgroundF4F7FE: #F4F7FE;
    --backgroundDarkBlue4339F2: #4339F2;
    --backgroundGreen34B53A: #34B53A;
    --backgroundOrangeFFB200: #FFB200;
    --backgroundpurple9E00FF: #9E00FF;
    --greenSignalDotBg01B529: #01B529;
    --darkOrangeBgDot: #FF5C00;
    --darkPurplebackGDot: #5500DF;
    --liteGreyTABSBgF2F5FF: #F2F5FF;
    --OrangeSignalTableFBA600: #FBA600;
    --purpleSignalTableCD18D0: #CD18D0;
    --successBgSignal18D0A4: #18D0A4;
    --dangerignalD0182E: #D0182E;
    --switchBackgroundColorE9F5FB: #E9F5FB;


    //  TEXT-COLOR VARIABLES
    --lightGreyColor999999: #999999;
    --darkBlackColor000: #000;
    --darkBlueColor4675F1: #4675F1;
    --lightGreytxtADADAD: #ADADAD;
    --whiteTxtColorfff: #fff;
    --navyBluetxtColor042486: #042486;
    --greyLightTxtColorValueA3AED0: #A3AED0;
    --darNavyTxtColor2B3674: #2B3674;
    --inputtxtgreyColor8F9BBA: #8F9BBA; // BORDER COLOR
    --lightGreyborderColorEEF3FC: #EEF3FC;
    --secondaryGrey707EAE: #707EAE;
    --borderBottomF6F6F6: #F6F6F6;
    --borderBottomBluish34528A: #34528A;
    --greenTextColor34B53A: #34B53A;
    --modalHeadTxt34528A: #34528A;
    --addEditBelowBorderE9F5FB: #E9F5FB;
    --fileGreyColorADADAD: #ADADAD;

}


// Delivery boys map styles
.map-container {
    height: 100vh !important;
    overflow-x: hidden;
    margin-left: 250px;
    min-height: calc(100vh - 80px);
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    .leaflet-container {
        width: 100% !important;
        height: 80vh !important;
        z-index: 0 !important;
    }
}