.doctor-wrapper {
  .innerSearchItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .leftFilterTop {
    display: flex;
  }

  .topSearch {
    position: relative;
    width: 500px;
    margin-right: 20px;

    .searchDoc {
      border: none;
      height: 40px;
      padding-left: 30px;
      width: 100%;

      &::placeholder {
        color: var(--inputtxtgreyColor8F9BBA);
        font-size: 14px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .searchInner {
      position: absolute;
      top: 14px;
      left: 10px;
    }
  }

  .topFilter {
    height: 40px;
    width: 40px;
    background-color: var(--whiteBackGroundColorfff);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .slideBelow {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }

  .cardLink {
    text-decoration: none !important;

    .doctorsDetailsCard {
      padding: 10px;
      background-color: var(--whiteBackGroundColorfff);
      border-radius: 20px;
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;
      width: 100%;

      .disable_img {
        position: absolute;
        left: 10px;
        top: 5px;
        img {
          width: 20px;
          height: 20px;
        }
      }

      .docInsideImg {
        cursor: pointer;
        margin-bottom: 10px;
        width: 100%;
        height: 192px;
        object-fit: contain;
        border-radius: 20px;
      }

      .ratingDet {
        position: absolute;
        top: 25px;
        right: 25px;
        display: flex;
        align-items: center;

        .innerRate {
          height: 16px;
          width: 16px;
          margin-right: 5px;
        }

        .rateTxt {
          font-size: 12px;
          font-weight: 600;
        }
      }

      .docDetailsTxt {
        text-align: center;

        h1 {
          color: var(--navyBluetxtColor042486);
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0px !important;
        }

        label {
          font-size: 12px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }
    }
  }
}

// DOCTOR DETAILS STYLES
.doctorDetails-wrapper {
  .mainDetailCard {
    background-color: var(--whiteBackGroundColorfff) !important;
    border-radius: 20px;
    height: calc(100% - 40px);
    margin-bottom: 40px;

    .topSection {
      padding: 20px;
      height: 80px;
      background-color: var(--darkBackgroundcolor4675F1);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 70px;

      .innerImg {
        position: relative;

        .docImg {
          height: 130px;
          width: 120px;
          border-radius: 20px;
        }

        .docDetTxt {
          display: flex;
          position: absolute;
          top: 70px;
          left: 130px;
          min-width: 200px;

          .docName {
            font-size: 18px;
            color: var(--navyBluetxtColor042486);
            margin-bottom: 0px;
          }

          .docDomain {
            font-size: 12px;
            color: var(--greyLightTxtColorValueA3AED0);
          }

          .disable_img {
            margin-left: 5px;
            margin-top: -2px;
            // position: absolute;
            // left: 10px;
            // top: 5px;
            img {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }

    // TABS SECTION
    .doctorTabs {
      padding: 20px;

      .nav-pills {
        width: 100%;
        background-color: var(--liteGreyTABSBgF2F5FF);
        border-radius: 10px;
        padding: 5px;
        margin-bottom: 20px;
      }

      .nav-item {
        width: 20%;
        text-align: center;
      }

      .nav-link {
        color: var(--navyBluetxtColor042486) !important;
      }

      .nav-link:hover {
        color: var(--darkBlueColor4675F1);
      }

      .nav-pills .nav-link.active {
        background-color: var(--darkBackgroundcolor4675F1);
        color: #fff !important;
        transition: all 0.5s;

        &:hover {
          color: #fff;
        }
      }

      .headTittle {
        border-bottom: 1px solid var(--borderBottomF6F6F6);
        margin-bottom: 20px;

        .heading {
          font-size: 18px;
          color: var(--navyBluetxtColor042486);
        }
      }

      .AmbulanceHead {
        color: var(--navyBluetxtColor042486);
        font-size: 18px;
      }

      .belowLine {
        border-bottom: 1px solid var(--borderBottomF6F6F6);
        margin-bottom: 20px;
      }

      .keyValueContent {
        margin-bottom: 20px;

        .Key {
          font-size: 14px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        ul {
          padding-left: 20px !important;

          li {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }
        }

        .imagesBunch {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          align-items: center;

          img {
            margin: 5px 5px 0px 0px;
            height: 101px;
            width: 101px;
            border-radius: 20px;
          }
        }

        .value {
          font-size: 16px;
          color: var(--navyBluetxtColor042486);
        }
      }

      .teamsCard {
        background-color: var(--whiteBackGroundColorfff);
        padding: 10px;
        border-radius: 20px;

        img {
          height: 150px;
          width: 100%;
          object-fit: contain;
          margin-bottom: 10px;
        }

        .detailsInner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .NameDOc {
            font-size: 14px;
            color: var(--navyBluetxtColor042486);
            margin-bottom: 0px !important;
            font-weight: bold;
          }

          .DomainDoc {
            font-size: 12px;
            color: var(--greyLightTxtColorValueA3AED0);
          }
        }
      }

      .flexyDoctorsInner {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        flex-wrap: wrap;

        .cardMd25 {
          &:last-child {
            margin-right: 0px;
          }

          margin-right: 20px;
          min-width: 18%;
          margin-bottom: 20px;
        }
      }

      .moreMt {
        margin-top: 20px;
      }

      .mainAppointments {
        .allApointmentsRow {
          border-bottom: 1px solid var(--borderBottomF6F6F6);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          margin-bottom: 10px;

          .headTxt {
            font-size: 18px;
            color: var(--navyBluetxtColor042486);
            margin-bottom: 0px !important;
            // font-weight: 600;
          }

          .FilImg {
            height: 24px;
            width: 24px;
          }
        }
      }

      .heightScroller {
        height: 470px;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none !important;
        }
      }

      .bankDetailsCard {
        background-color: var(--whiteBackGroundColorfff);
        padding: 10px;
        border-radius: 20px;
        // height: 260px;
        margin-bottom: 10px;

        .heading {
          font-size: 18px;
          color: var(--navyBluetxtColor042486);
          border-bottom: 1px solid var(--borderBottomF6F6F6);
          margin-bottom: 20px;
        }

        .detailsHilder {
          h1 {
            font-size: 14px;
            color: var(--navyBluetxtColor042486);
          }
        }

        .detailsInsider {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 20px;

          .key {
            font-size: 14px;
            color: var(--greyLightTxtColorValueA3AED0);
            width: 50%;
          }

          .Value {
            font-size: 16px;
            color: var(--navyBluetxtColor042486);
            width: 50%;
          }
        }

        .graphs {
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-between;
          // align-items: center;

          .profitGraph,
          .lossGraph {
            padding: 10px 15px;
            background-color: var(--liteGreyTABSBgF2F5FF);
            border-radius: 20px;
            width: 100%;
            margin-bottom: 20px;

            .graph {
              text-align: center !important;

              .apexcharts-yaxis,
              .apexcharts-xaxis-texts-g,
              .apexcharts-xaxis,
              #SvgjsLine1432,
              .apexcharts-xcrosshairs,
              .apexcharts-xaxis-tick {
                display: none !important;
              }
            }

            #apexcharts59wtettxf {
              width: 100% !important;

              &:first-child {
                foreignObject {
                  width: 100% !important;
                  height: 100% !important;
                }
              }
            }

            .apexcharts-yaxis-texts-g {
              display: none !important;
            }

            .apexcharts-xaxis-texts-g {
              display: none !important;
            }

            .InsideTxt {
              color: var(--navyBluetxtColor042486);
              font-size: 14px;
              font-weight: 600;
            }

            .numberDet {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .dollarAmnt {
                font-size: 30px;
                color: var(--darkBlackColor000);
              }

              .plusPercent {
                font-size: 14px;
                color: var(--greenTextColor34B53A);
              }

              .lossPercent {
                font-size: 14px;
                color: var(--dangerignalD0182E);
              }
            }
          }

          .lossGraph {
            padding: 10px 15px;
            background-color: var(--liteGreyTABSBgF2F5FF);
            border-radius: 20px;
            width: 100%;
          }
        }
      }

      .ratingBox {
        .totalReviewCard {
          padding: 10px;
          border-right: 1px solid var(--borderBottomF6F6F6);

          .headTxt {
            font-size: 16px;
            color: var(--darNavyTxtColor2B3674);
          }

          .amntTxt {
            font-size: 30px;
            font-weight: 600;
            color: var(--darNavyTxtColor2B3674);
            margin-bottom: 0px;
          }

          .detailTxt {
            font-size: 12px;
            color: var(--greyLightTxtColorValueA3AED0);
            font-family: "Edu SA Beginner", cursive !important;
          }
        }

        .innerStars {
          display: flex;

          p {
            margin-right: 18px;
          }

          img {
            margin-right: 5px;
          }
        }

        .ratingStars {
          padding: 10px;

          .starLevels {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .starImg {
              margin-right: 5px;
              height: 12px !important;
              width: 12px !important;
            }

            .starAmnt {
              font-size: 12px;
              color: var(--navyBluetxtColor042486);
              margin-right: 10px;
            }

            .greenBar {
              width: 100%;

              .progress-bar {
                background-color: #05cd61;
                height: 8px;
                border-radius: 20px;
              }
            }

            .bottlegreenBar {
              width: 100%;

              .progress-bar {
                background-color: #bceb00;
                height: 8px;
                border-radius: 20px;
              }
            }

            .liteOrangeBar {
              width: 100%;

              .progress-bar {
                background-color: #ffd600;
                height: 8px;
                border-radius: 20px;
              }
            }

            .darkOrangeBar {
              width: 100%;

              .progress-bar {
                background-color: #ff8329;
                height: 8px;
                border-radius: 20px;
              }
            }

            .redBar {
              width: 100%;

              .progress-bar {
                background-color: #e81300;
                height: 8px;
                border-radius: 20px;
              }
            }
          }
        }

        .belowLine {
          width: 100% !important;
          border-bottom: 1px solid var(--borderBottomF6F6F6);
          margin-bottom: 20px;
        }

        .petDetailsCard {
          padding: 10px;
          border-right: 1px solid var(--borderBottomF6F6F6);
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
            height: 100px;
            width: 100px;
          }

          .cardDetails {
            display: flex;
            flex-direction: column;

            .headingTx {
              font-size: 16px;
              font-weight: 600;
              color: var(--navyBluetxtColor042486);
              margin-bottom: 15px;
            }

            .reviewTxt {
              color: var(--greyLightTxtColorValueA3AED0);
              font-size: 14px;

              span {
                color: var(--navyBluetxtColor042486) !important;
              }
            }

            .spendsTxt {
              color: var(--greyLightTxtColorValueA3AED0);
              font-size: 14px;

              span {
                color: var(--navyBluetxtColor042486) !important;
              }
            }
          }
        }

        .regularCheqCard {
          padding: 10px;

          .ratingDetailsHead {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            .nameAndDate {
              display: flex;
              align-items: center;

              .nameLab {
                font-size: 16px;
                font-weight: 600;
                color: var(--navyBluetxtColor042486);
                border-right: 1px solid var(--borderBottomF6F6F6);
                padding-right: 10px;
                margin-right: 10px;
              }

              .dateLab {
                font-size: 12px;
                color: var(--greyLightTxtColorValueA3AED0);
              }
            }

            .starRate {
              .starIcon {
                height: 16px;
                width: 16px;
                margin-right: 5px;
              }

              .rateNum {
                font-size: 12px;
              }
            }
          }

          .paraFlow {
            max-height: 90px;
            overflow-y: auto;

            &::-webkit-scrollbar {
              display: none;
            }

            .insideParaDet {
              font-size: 14px;
              color: var(--greyLightTxtColorValueA3AED0);
              text-align: justify;
            }
          }
        }
      }
    }
  }
}
.btn-close:focus {
  box-shadow: none;
}
.subscription_main_div {  
  padding: 20px;

  .sub_plan {
    color: var(--navyBluetxtColor042486);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: left;

    span {
      font-weight: 600;
    }
  }
  .subscription_div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 15px;
    border-bottom: 1px solid var(--greyLightTxtColorValueA3AED0);
    .subscription_header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      .plan_name {
        // color: var(--greyLightTxtColorValueA3AED0);
        color: var(--navyBluetxtColor042486);
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 24px;
      }
      .subscription_plan_price {
        font-weight: 500;
      }
    }
    .sub_plan {
      color: var(--navyBluetxtColor042486);
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
      text-align: left;
    }
  }

  .group {
    label {
      margin-bottom: 5px;
    }
    .select_input {
      margin-bottom: 20px;
    }
  }
  .end-date {
    color: var(--navyBluetxtColor042486);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }

}
.cancel_btn {
  padding: 5px 15px;
  border-radius: 10px;
  background-color: transparent;
  color: var(--darkBlueColor4675F1);
  border: 1px solid var(--darkBlueColor4675F1);
  margin-right: 16px;
}
.apply_btn {
  border: none;
  padding: 5px 20px;
  border-radius: 10px;
  color: #fff;
  background-color: var(--darkBlueColor4675F1);
}

// ADD DOCTOR STYLES
.adddoctor-wrapper {
  .innerSearchItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .leftFilterTop {
    display: flex;
  }

  .topSearch {
    position: relative;
    width: 500px;
    margin-right: 20px;

    .searchDoc {
      border: none;
      height: 40px;
      padding-left: 30px;

      &::placeholder {
        color: var(--inputtxtgreyColor8F9BBA);
        font-size: 14px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .searchInner {
      position: absolute;
      top: 14px;
      left: 10px;
    }
  }

  .topFilter {
    height: 40px;
    width: 40px;
    background-color: var(--whiteBackGroundColorfff);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  #scrollable-table {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }

  .slideBelow {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }

  .doctorsDetailsCard {
    padding: 10px;
    background-color: var(--whiteBackGroundColorfff);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;

    .cardLink {
      text-decoration: none !important;

      .docInsideImg {
        margin-bottom: 10px;
        width: 100%;
        height: 250px;
        object-fit: contain;
      }
    }

    .docDetailsTxt {
      text-align: center;

      h1 {
        color: var(--navyBluetxtColor042486);
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0px !important;
      }

      label {
        font-size: 12px;
        color: var(--greyLightTxtColorValueA3AED0);
      }
    }

    .innerButton {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

// VIEW DICTOR DETAILS
.viewDoctor-wrapper {
  .mainDetailCard {
    background-color: var(--whiteBackGroundColorfff) !important;
    border-radius: 20px;
    height: calc(100% - 40px);
    margin-bottom: 40px;

    .topSection {
      padding: 20px;
      height: 80px;
      background-color: var(--darkBackgroundcolor4675F1);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 70px;

      .innerImg {
        position: relative;

        .docImg {
          height: 120px;
          width: 120px;
          border-radius: 20px;
        }

        .docDetTxt {
          position: absolute;
          top: 70px;
          left: 130px;
          min-width: 200px;

          .docName {
            font-size: 18px;
            color: var(--navyBluetxtColor042486);
            margin-bottom: 0px;
          }

          .docDomain {
            font-size: 12px;
            color: var(--greyLightTxtColorValueA3AED0);
          }
        }
      }
    }

    .belowDetails {
      padding: 20px;

      .headTittle {
        border-bottom: 1px solid var(--borderBottomF6F6F6);
        margin-bottom: 20px;

        .heading {
          font-size: 18px;
          color: var(--navyBluetxtColor042486);
        }
      }

      .AmbulanceHead {
        color: var(--navyBluetxtColor042486);
        font-size: 18px;
      }

      .moreMt {
        margin-top: 20px;
      }

      .belowLine {
        border-bottom: 1px solid var(--borderBottomF6F6F6);
        margin-bottom: 20px;
      }

      .keyValueContent {
        margin-bottom: 20px;

        .Key {
          font-size: 14px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        ul {
          padding-left: 20px !important;

          li {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }
        }

        .imagesBunch {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          align-items: center;

          img {
            margin: 5px 5px 0px 0px;
            height: 101px;
            width: 101px;
            border-radius: 19px;
          }
        }

        .value {
          font-size: 16px;
          color: var(--navyBluetxtColor042486);
        }
      }
    }
  }
}

// Doctor File Tabs
#docsTabs {
  height: 280px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .fileInside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .imgHolder {
      margin-bottom: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      background-color: #4675f1;
    }

    h1 {
      color: #2b3674;
      font-size: 14px;
    }
  }

  .nav-pills {
    background-color: #fff !important;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
  }

  .nav-item {
    width: 22% !important;
    margin-right: 20px;
    margin-bottom: 10px;

    &:last-child {
      margin-right: 0px;
    }
  }

  .nav-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: #f2f5ff !important;
    height: 130px;
    border-radius: 20px !important;
  }

  .nav-pills .nav-link.active {
    background-color: #4675f1 !important;

    .fileInside {
      .imgHolder {
        border: 2px dashed #fff;
      }

      h1 {
        color: #fff;
      }
    }
  }
}

.doctorDetails-wrapper #docsTabs .nav-pills {
  background-color: #fff !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  flex-wrap: wrap !important;
}

.doctorDetails-wrapper #docsTabs .nav-item {
  width: 22% !important;
  margin-right: 20px;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0px;
  }
}

.doctorDetails-wrapper #docsTabs .nav-link {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  background-color: #f2f5ff !important;
  height: 130px;
  border-radius: 20px !important;
}

.doctorDetails-wrapper #docsTabs .nav-pills .nav-link.active {
  background-color: #4675f1 !important;

  .fileInside {
    .imgHolder {
      border: 2px dashed #fff;
    }

    h1 {
      color: #fff;
    }
  }
}

.allSignsFlex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .signatureHolder {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f5ff;
    border-radius: 20px;
    height: 130px;
    width: 22%;
    margin: 0px 20px 10px 0px;

    &:last-child {
      margin: 0px 0px 10px 0px;
    }

    img {
      height: 100px;
      width: 100px;
    }
  }
}

// }
// Default
.progress {
  --bs-progress-bg: transparent !important;
  height: 8px !important;
}
