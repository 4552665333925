@import 'variable.scss';

.main-wrapper {

    .insideCard {
        background-color: var(--whiteBackGroundColorfff);
        border-radius: 20px;
        padding: 18px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 20px;

        .cardinnerImg {
            margin-right: 20px;
        }

        .detailsCard {
            display: flex;
            flex-direction: column;

            .CardName {
                font-size: 14px;
                color: var(--greyLightTxtColorValueA3AED0);
                margin-bottom: 0px;
            }

            .cardAMount {
                font-size: 24px;
                color: var(--darNavyTxtColor2B3674);
                font-weight: 600;
                margin-bottom: 0px;
            }
        }
    }

    .revenueChartLeft {
        background-color: var(--whiteBackGroundColorfff);
        padding: 10px 20px;
        border-radius: 20px;
        margin-bottom: 20px;
        min-height: 300px;

        .headingSetup {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .chartTitle {
                color: var(--navyBluetxtColor042486);
                font-size: 16px;
                font-weight: 500;
            }

            .selection-inputs {
                display: flex;
                // justify-content: space-between;
            }
        }

        .apexcharts-menu-icon {
            display: none !important;
        }

        .highlightsTxt {
            display: flex;
            justify-content: center;

            .apoointment {
                display: flex;
                align-items: center;

                margin-right: 20px;

                .blueTick {
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background-color: var(--backgroundDarkBlue4339F2);
                    margin-right: 8px;
                }

                .greenTick {
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background-color: var(--backgroundGreen34B53A);
                    margin-right: 8px;
                }

                .orangeTick {
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background-color: var(--backgroundOrangeFFB200);
                    margin-right: 8px;
                }

                .purpleTick {
                    height: 10px;
                    width: 10px;
                    border-radius: 50%;
                    background-color: var(--backgroundpurple9E00FF);
                    margin-right: 8px;
                }

                .tickTxt {
                    font-size: 12px;
                    color: var(--darNavyTxtColor2B3674);
                }
            }
        }
    }

    .revenueUserRight {
        background-color: var(--whiteBackGroundColorfff);
        padding: 10px 20px;
        border-radius: 20px;
        margin-bottom: 20px;
        min-height: 300px;

        .headingSetup {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            .chartTitle {
                color: var(--navyBluetxtColor042486);
                font-size: 16px;
                font-weight: 500;
            }

            .selection-inputs {
                display: flex;
                // justify-content: space-between;
            }
        }

        .donutsChart {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .leftSideDetails {
                margin-left: 30px;

                .percentageDetails {
                    margin-bottom: 15px;

                    .signalDetails {
                        display: flex;
                        align-items: center;
                        margin-bottom: 2px;

                        .greenSignalDot {
                            height: 10px;
                            width: 10px;
                            border-radius: 50%;
                            background-color: var(--greenSignalDotBg01B529);
                            margin-right: 5px;
                        }

                        .orangeSignalDot {
                            height: 10px;
                            width: 10px;
                            border-radius: 50%;
                            background-color: var(--backgroundOrangeFFB200);
                            margin-right: 5px;
                        }

                        .darkOranSignalDot {
                            height: 10px;
                            width: 10px;
                            border-radius: 50%;
                            background-color: var(--darkOrangeBgDot);
                            margin-right: 5px;
                        }

                        .darkPurpleSignalDot {
                            height: 10px;
                            width: 10px;
                            border-radius: 50%;
                            background-color: var(--darkPurplebackGDot);
                            margin-right: 5px;
                        }

                        .signalTxt {
                            color: var(--greyLightTxtColorValueA3AED0);
                            font-size: 12PX;
                        }
                    }

                    .perPart {
                        .percentageTxt {
                            font-size: 18px;
                            color: var(--darNavyTxtColor2B3674);
                            font-weight: 700;
                            margin-left: 15px;
                        }
                    }
                }
            }
        }

        .rightSidechart {
            .apexcharts-legend {
                display: none !important;
            }

            .apexcharts-series .apexcharts-pie-series {

                .apexcharts-donut-slice-1 {
                    background-color: red !important;
                }
            }
        }
    }

    .tableInner {
        background-color: var(--whiteBackGroundColorfff);
        padding: 10px 20px;
        border-radius: 20px;
        margin-bottom: 20px;

        .headingSetup {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .chartTitle {
                color: var(--navyBluetxtColor042486);
                font-size: 16px;
                font-weight: 500;
            }
        }
    }

}

//  STyles for Notification Screen\
.notificationWrapper {
    background-color: #fff;
    min-height: calc(100vh - 110px);
    padding: 20px;
    border-radius: 20px;

    .insideTable {
        width: 100%;

        .tableNoti {
            width: 100%;

            thead {
                tr {
                    border-bottom: .5px solid #EEF3FC;

                    th {
                        position: sticky;
                        top: 0px;
                        background-color: #fff;
                        color: #A3AED0;
                        font-size: 12px;
                        padding-bottom: 5px;
                        // width: 20%;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 10px 10px 10px 0px;
                        font-size: 12px;
                        color: #042486;
                    }
                }
            }
        }
    }
}