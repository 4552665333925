.revenueReports-wrapper {
  .infinite-scroll-component {
    &::-webkit-scrollbar {
      display: none !important;
    }
  }

  .reportsTabs {
    .nav-item {
      margin-right: 15px;
    }

    .nav-link.active {
      border-radius: 0;
      background: none;
      padding-bottom: 5px;
      border-bottom: 2px solid #042486;
      font-weight: 600;
      color: #042486;
    }

    .nav-link {
      padding: 0;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0em;
      color: #a3aed0;
    }
  }

  .doc-name-back-btn {
    label {
    }

    span {
    }
  }
}

.exportButton {
  button {
    height: 40px;
    width: 40px;
    background-color: #fff;
    border: none;
    border-radius: 10px;
  }
}

.sectionHeader {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  p,
  a {
    font-size: 16px;
    font-weight: 500;
    color: #042486;
    text-decoration: none;
  }
}

.AdminCardView {
  padding: 10px;
  background-color: var(--whiteBackGroundColorfff);
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;

  .docInsideImg {
    margin-bottom: 10px;
    width: 100%;
  }

  .ratingDet {
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    align-items: center;

    .innerRate {
      height: 16px;
      width: 16px;
      margin-right: 5px;
    }

    .rateTxt {
      font-size: 12px;
      font-weight: 600;
    }
  }

  .docDetailsTxt {
    text-align: center;

    h1 {
      color: var(--navyBluetxtColor042486);
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0px !important;
    }

    label {
      font-size: 12px;
      color: var(--greyLightTxtColorValueA3AED0);
    }
  }

  .medicalbelowDetails {
    display: flex;
    align-items: center;
    margin-bottom: 5px;

    .headTxt {
      font-size: 14px;
      color: var(--navyBluetxtColor042486);
      font-weight: 500;
      border-right: 1px solid var(--borderBottomF6F6F6);
      padding-right: 5px;
      margin-right: 5px;
    }

    .starFlex {
      display: flex;
      align-items: center;
      margin-right: 5px;

      .star {
        height: 16px;
        width: 16px;
      }
    }
  }

  .clockDet {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .clock {
      height: 20px;
      width: 20px;
      margin-right: 10px;
    }

    .timeDet {
      font-size: 12px;
      color: var(--greyLightTxtColorValueA3AED0);
    }
  }
}

.slideBelowInUser {
  max-height: calc(100vh - 225px);
  overflow-y: auto;
}

.table-wrapper {
  background-color: var(--whiteBackGroundColorfff);
  padding: 0px 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  height: calc(100vh - 260px);
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  .renewBtn {
    border: none;
    background-color: #3377f7;
    color: #fff;
    height: 40px;
    width: 120px;
    border-radius: 12px;
  }

  .defaultTable {
  }

  .pageData {
    position: absolute;
    bottom: 5px;

    label {
      color: #042486;
    }
  }

  .pageDataElse {
    position: absolute;
    bottom: -10px;

    label {
      color: #042486;
    }
  }

  .map-link {
    text-decoration: none;
    color: #34528a;
  }

  .tableImg {
    margin-right: 5px;
  }

  .defaultTable {
    width: 100%;

    thead th {
      position: sticky;
      top: 0;
      background-color: #fff;
    }
  }

  tbody {
    tr td {
      padding: 10px;
      font-weight: bold;
      font-size: 12px;
      color: #34528a;
    }

    .condtionalTd {
      .boxHolder {
        position: relative;
      }

      .form-control {
        // width: 300px;
        padding-right: 50px;

        &:focus {
          box-shadow: none;
          outline: none;
        }
      }

      .roundedImg {
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: 1px solid #3377f7;
      }
    }

    .ClickableTD {
      text-decoration: underline;
      cursor: pointer;
    }

    .CheckUP {
      p {
        background-color: #fba600;
        color: white;
        padding: 7px 12px;
        text-align: center;
        border-radius: 15px;
        font-weight: 400;
      }
    }

    .Vaccine {
      p {
        background-color: #cd18d0;
        color: white;
        padding: 7px 12px;
        text-align: center;
        border-radius: 15px;
        font-weight: 400;
      }
    }

    .Surgery {
      p {
        background-color: #18d0a4;
        color: white;
        padding: 7px 12px;
        text-align: center;
        border-radius: 15px;
        font-weight: 400;
      }
    }

    .Test {
      p {
        background-color: #d0182e;
        color: white;
        padding: 7px 12px;
        text-align: center;
        border-radius: 15px;
        font-weight: 400;
      }
    }
  }

  thead {
    tr {
      border-bottom: 1px solid var(--lightGreyborderColorEEF3FC);
    }

    tr th {
      padding: 10px;
      color: var(--greyLightTxtColorValueA3AED0);
      font-size: 12px;
    }
  }
}

.commmon-top-search-section {
  .innerSearchItem {
    display: flex;
    align-items: center;
    margin: 20px 0;
  }

  .topSearch {
    position: relative;
    width: 460px;
    margin-right: 20px;

    .searchDoc {
      border: none;
      height: 40px;
      padding-left: 30px;

      &::placeholder {
        color: var(--inputtxtgreyColor8F9BBA);
        font-size: 14px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .searchInner {
      position: absolute;
      top: 14px;
      left: 10px;
    }
  }

  .topFilter {
    height: 40px;
    width: 40px;
    background-color: var(--whiteBackGroundColorfff);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    .dropdown-toggle::after {
      content: none !important;
    }

    .dropdown-item {
      color: #042486;
    }

    .btn {
      border: none !important;
    }

    .btn.show {
      border-color: transparent !important;

      &:focus {
        outline: none !important;
      }
    }
  }
}

.center {
  text-align: center;
}

.Subscribtion_main {
  .subscription_chart_main {
    background-color: var(--whiteBackGroundColorfff);
    padding: 10px 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    min-height: 300px;
    width: 100%;

    .chart_Head {
      display: flex;
      justify-content: space-between;

      .chartTitle {
        font-size: 16px;
        font-weight: 500;
        color: #042486;
      }
    }
  }
}

.Mini_chart_Catagity {
  background-color: var(--whiteBackGroundColorfff);
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 20px;
  min-height: 300px;
  width: 100%;
  cursor: pointer;

  .catagiry_logo {
    background-color: #a3aed081;
    padding: 7px 7px;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  .catagity_Name {
    font-size: 14px;
    font-weight: 400;
    color: #042486;
  }

  .Catagiry_amount {
    font-size: 40px;
    font-weight: 700;
    color: #042486;
    display: flex;
    align-items: center;

    .cartagity_Graph_indicator {
      margin-left: 15px;
    }
  }

  .graphValue {
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .red {
    color: #ff3a29;
  }

  .orange {
    color: #ffb200;
  }

  .blue {
    color: #02a0fc;
  }

  .perpule {
    color: #4339f2;
  }

  .green {
    color: #34b53a;
  }
}

.red {
  color: #ff3a29 !important;
}

.arrow_box {
  padding: 7px 17px;
  background-color: #042486;
  color: white;
}

.arrow_box_sec {
  padding: 7px 17px;
  background-color: #00d50a;
  color: white;
}

.innerDatils {
  font-size: 18px;
  font-weight: 700;
  color: #042486;
}

.Other-wrapper {
  height: calc(100vh - 120px);
  display: flex;
  justify-content: center;
  align-items: center;
}

// .topSearch{
//   width: 100% !important;
// }

// WITHDRAW REQUEST ACCEPT AND REJECT MODAL CSS
.withdraw-modal {
  .modal-content {
    border-radius: 10px;
  }
}
.accept-request-modal {
  .withdraw-modal-content {
    .form-items {
      label {
        color: #34528a;
      }
    }
  }

  .accept-btn {
    border: none;
    padding: 5px 15px;
    border-radius: 5px;
    background-color: #0cb10c;
    color: #fff;
  }
}

// WITHDRAW REQUEST ACCEPT AND REJECT MODAL CSS
