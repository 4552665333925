@media all and (max-width : 991px) {
    .landingImg {
        display: none;
    }

    .authMain {
        .signinForm {
            padding-top: 30px;
            margin: 50px;
        }
    }

    .routButton {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .topSearch {
        margin-right: 0 !important;
    }

    .table-wrapper {
        overflow-x: auto;
    }

    .defaultTable {
        min-width: 1000px;
    }

    #newTabMai {
        div {
            width: 50%;
        }
    }

    .bankDetailsCard {
        margin-bottom: 20px;
    }

    .routButton {
        button {
            margin-bottom: 20px;
        }
    }

    .quant,
    .price {
        display: flex;
        justify-content: space-around;
        border-left: none !important;
        text-align: end;
    }

    // .topFilter {
    //     margin-bottom: 20px;
    // }

    .dNoneResponsive {
        display: none;
    }
}

@media all and (max-width : 768px) {

    .authMain {

        .signinForm {
            margin: 20px;
        }
    }

    .innerSearchItem {
        flex-direction: column;
    }

    .tasbInerTwo {
        div {
            width: 50% !important;
            margin-bottom: 0 !important;

            a {
                font-size: 12px;
            }
        }
    }

    .topFilter {
        margin-bottom: 10px;
    }

    .selectBox {
        margin-bottom: 20px;
    }

    .doctorDetails-wrapper .mainDetailCard {
        height: auto !important;
    }

    .bankDetailsCard {
        height: auto !important;
    }

    .exportButton,
    .routButton {
        button {
            margin-bottom: 10px;
        }
    }

    #newTabMai {
        div {
            width: 100%;
        }
    }

    .graphs {
        flex-direction: column;

        div {
            width: 100% !important;

            &:first-child {
                margin-bottom: 20px;
            }
        }
    }

    .cardImg {
        display: flex;
        margin: auto;
    }
}

@media all and (max-width : 580px) {
    #newTabMai {
        div {
            margin-bottom: 10px;
        }
    }
}

@media all and (max-width : 480px) {}