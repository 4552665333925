@import 'variable.scss';

.mainHeader {
    position: relative;

    .top-header {
        background-color: var(--lightBodyBackgroundEEF3FC);
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        z-index: 10;
        padding: 20px;
        box-shadow: 0px 0px 8px 0px #4675F1;
    }

    .headingBreads,
    .breadCrumbsTop {
        .leftHeadTxt {
            margin-left: 250px;
            transition: all .5s;
            font-size: 24px;
            font-weight: 600;
            color: var(--navyBluetxtColor042486);
        }

        .leftHeadBread {
            margin-left: 250px;
            transition: all .5s;
            font-size: 14px;
            color: var(--secondaryGrey707EAE);

            a {
                text-decoration: none;
                font-weight: 600;
            }
        }

        .leftSideBread {
            margin-left: 60px;
            transition: all .5s;
            font-size: 14px;
            color: var(--secondaryGrey707EAE);

            a {
                text-decoration: none;
                font-weight: 600;
            }
        }

        .leftSideCloseBar {
            margin-left: 60px;
            transition: all .5s;
            padding-right: 20px;
            font-size: 24px;
            font-weight: 600;
            color: var(--navyBluetxtColor042486);
        }
    }

    .searchBlog {
        .innerSearch {
            position: relative;
            background-color: var(--whiteBackGroundColorfff);
            padding: 10px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            width: 410px;
            transition: all .8s;

            .innerForm {
                padding-left: 40px;
                background-color: var(--lightBlueBackgroundF4F7FE);
                border: none;
                border-radius: 30px;
                width: 250px;
                height: 40px;
                margin-right: 20px;

                &:focus {
                    box-shadow: none;
                }

                &::placeholder {
                    color: var(--inputtxtgreyColor8F9BBA);
                }
            }

            .insideIcons {
                margin-right: 20px;
            }

            .notiBox {
                position: relative !important;
                cursor: pointer;

                .bagde {
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    color: #fff;
                    background-color: #4675F1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0px;
                    right: 17px;

                    span {
                        font-size: 8px;
                    }
                }
            }

            .searchInside {
                position: absolute;
                top: 24px;
                left: 30px;
            }

            .circleImg {
                cursor: pointer;
                height: 40px;
                width: 40px;
            }
        }

        .smallInnerSearch {
            position: relative;
            background-color: var(--whiteBackGroundColorfff);
            padding: 10px;
            border-radius: 30px;
            display: flex;
            align-items: center;
            width: 140px;
            transition: all .6s;

            .innerForm {
                display: none;
            }

            .insideIcons {
                margin-right: 20px;
            }

            .notiBox {
                position: relative !important;
                cursor: pointer;

                .bagde {
                    height: 16px;
                    width: 16px;
                    border-radius: 50%;
                    color: #fff;
                    background-color: #4675F1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: absolute;
                    top: 0px;
                    right: 17px;

                    span {
                        font-size: 8px;
                    }
                }
            }

            .searchInside {
                display: none;
            }

            .circleImg {
                cursor: pointer;
                height: 40px;
                width: 40px;
            }
        }
    }

    justify-content: end;
}