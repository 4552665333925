// All Global changes on screen size 768px
@media all and (max-width : 768px) {
    .clickAbleArrow {
        img {
            display: block !important;
        }
    }
    .cx-sideBar{
        .logoMain{
            a{
                img{
                    height: 50px;
                    width: 50px;
                }
            }
        }
    }
    #cx-main,
    .leftHeadTxt,
    .leftHeadBread {
        margin-left: 60px !important;
    }

    .cx-active {
        margin-left: 0px;
    }
}

@media all and (max-width : 1340px) {
    .adddoctor-wrapper {
        .slideBelow {
            .AddcardsResponsive {
                width: 33%;
            }
        }
    }

    .doctor-wrapper {
        .cardsResponsive {
            width: 33%;
        }
    }

    .pharmacyShop-wrapper,
    .addPharmacy-wrapper {
        .slideBelow {
            .cardsResponsive {
                width: 33%;
            }
        }
    }
}

// Max Width 1240px
@media all and (max-width : 1240px) {
    .AameerResponsive {
        .widthResponsive {
            width: 50%;
        }
    }

    .masterProductList-wrapper {
        .MasterInvenTabs {
            .cardsResponsive {
                width: 33%;
            }
        }

        .selectBox,
        .topFilter {
            margin-bottom: 10px;
        }
    }

    .shopsInventory-wrapper {
        .cardsResponsive {
            width: 33%;
        }

    }
}

@media all and (max-width : 1110px) {
    .adddoctor-wrapper {
        .slideBelow {
            .AddcardsResponsive {
                width: 50%;
            }

            .innerButton {
                flex-direction: column;

                .rejectBtn {
                    width: 100%;
                    margin-bottom: 10px;
                }

                .AcceptBtn {
                    width: 100%;
                }
            }
        }
    }

    .pharmacyShop-wrapper,
    .addPharmacy-wrapper {
        .slideBelow {
            .cardsResponsive {
                width: 50%;

                .doctorsDetailsCard {
                    .belowBtns {
                        flex-direction: column;

                        a {
                            width: 100% !important;

                            .rejectBtn {
                                width: 100% !important;
                                margin-bottom: 10px;
                            }

                            .AcceptBtn {
                                width: 100% !important;
                            }
                        }
                    }

                    .innerButton {
                        flex-direction: column;

                        .rejectBtn {
                            margin-bottom: 10px;
                            width: 100%;
                        }

                        .AcceptBtn {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .pharmacyShop-wrapper {
        .innerSearchItem {
            flex-direction: column;
            align-items: start;

            .leftFilterTop {
                width: 100%;

                .topSearch {
                    width: 100%;

                    .searchDoc {
                        width: 95%;
                    }
                }
            }

            .routButton {
                margin-top: 10px;
            }
        }
    }

    .doctor-wrapper,
    .adddoctor-wrapper,
    .addPharmacy-wrapper {
        .innerSearchItem {
            flex-direction: column;
            align-items: start;

            .leftFilterTop {
                width: 100%;

                .topSearch {
                    width: 100%;

                    .searchDoc {
                        width: 95%;
                    }
                }
            }

            .routButton {
                margin-top: 10px;
            }
        }
    }
}

// Max Width 1240px
@media all and (max-width : 991px) {
    .doctor-wrapper {
        .innerSearchItem {
            margin-bottom: 10px;
        }

        .btnAdjustable {
            text-align: start !important;
            margin-bottom: 10px;
        }

        .slideBelow {
            .cardsResponsive {
                width: 50% !important;
                display: flex;
                justify-content: center !important;
            }

            .doctorsDetailsCard {
                width: 100%;

                .docInsideImg {
                    width: 100%;
                    height: 192px;
                    object-fit: contain;
                }
            }
        }
    }

    .masterProductList-wrapper {
        .MasterInvenTabs {
            .cardsResponsive {
                width: 50%;
            }
        }
    }

    .shopsInventory-wrapper {
        .cardsResponsive {
            width: 50%;
        }

        .topFilter {
            margin-bottom: 10px;
        }
    }

    .commmon-top-search-section {
        .innerSearchItem {
            margin: 10px 0px;
        }

        .topSearch {
            width: 100%;

            .searchDoc {
                width: 100%;
            }
        }

        .topFilter {
            margin-bottom: 10px;
        }
    }

    .revenueReports-wrapper {
        .nav-item {
            width: 100% !important;
            text-align: center !important;
        }

        .reportsTabs .nav-link.active,
        .reportsTabs .nav-link {
            padding: 8px !important;
        }
    }

    .modal-dialog {
        overflow-x: auto;
    }
}

// @media all and (max-width : 1240px)
@media all and (max-width : 768px) {
    #cx-main {
        padding: 20px 15px !important;
    }

    .AameerResponsive {
        .widthResponsive {
            width: 100%;
        }
    }

    .mainHeader {
        .top-header {
            justify-content: flex-end;
        }

        .bothHeaders {
            display: none !important;
        }

        .searchBlog {}
    }

    .adddoctor-wrapper {
        .responsiveNone {
            display: none;
        }
    }

    .doctorDetails-wrapper {
        .flexyDoctorsInner {
            .cardMd25 {
                width: 45%;
            }
        }
    }
}

// @media all and (max-width : 540px)
@media all and (max-width : 540px) {
    .mainHeader {
        .top-header {
            .searchBlog {
                .innerSearch {
                    width: 330px;

                    .innerForm {
                        width: 170px;
                    }
                }
            }
        }
    }

    .AameerResponsive {
        .revenueChartLeft {
            .highlightsTxt {
                flex-wrap: wrap;
            }
        }

        .revenueUserRight {
            .donutsChart {
                flex-direction: column;
            }

            .leftSideDetails {
                margin-left: 0px !important;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                align-items: center;
            }
        }
    }

    .doctor-wrapper,
    .adddoctor-wrapper,
    .pharmacyShop-wrapper {
        .slideBelow {
            .cardsResponsive {
                width: 100% !important;
                display: block !important;
                // justify-content: center !important;
            }

            .doctorsDetailsCard {
                width: 100%;

                .docInsideImg {
                    width: 100%;
                    height: auto;
                    object-fit: cover;
                }
            }
        }

        .btnAdjustable,
        .routButton {
            width: 100%;

            .innerBtn {
                width: 100%;
            }
        }
    }

    .adddoctor-wrapper {
        .innerSearchItem {
            flex-direction: column;
        }

        .slideBelow {
            .AddcardsResponsive {
                width: 100%;
            }
        }
    }

    .doctorDetails-wrapper {
        .flexyDoctorsInner {
            .cardMd25 {
                width: 100%;
            }
        }

        .detailsInsider {
            flex-direction: column;
            align-items: flex-start !important;

            .Value {
                width: 100% !important;
            }
        }
    }

    .pharmacyShop-wrapper,
    .addPharmacy-wrapper {
        .slideBelow {
            .cardsResponsive {
                width: 100%;
            }
        }

        .detailsInsider {
            flex-direction: column;
            align-items: flex-start !important;

            .Value {
                width: 100% !important;
            }
        }
    }

    .masterProductList-wrapper {
        .MasterInvenTabs {
            .cardsResponsive {
                width: 100%;
            }
        }
    }

    .shopsInventory-wrapper {
        .cardsResponsive {
            width: 100%;
        }
    }
}

// @media all and (max-width : 540px)
@media all and (max-width : 480px) {
    .mainHeader {
        .top-header {
            padding: 20px 10px !important;

            .searchBlog {
                .innerSearch {
                    width: 275px;

                    .insideIcons {
                        margin-right: 10px !important;
                    }

                    .innerForm {
                        width: 140px;
                    }
                }
            }
        }
    }

}

// @media all and (max-width : 540px)
@media all and (max-width : 380px) {
    .mainHeader {
        .top-header {
            padding: 20px 10px !important;

            .searchBlog {
                .innerSearch {
                    width: 260px;

                    .innerForm {
                        width: 120px;
                    }
                }
            }
        }
    }

    .doctor-wrapper {
        .rightFilters {
            width: 100%;

            .topFilter {
                width: 15%;
            }

            .routButton {
                width: 80%;

                .innerBtn {
                    width: 100%;
                }
            }
        }

        // .rightFilters {
        //     flex-direction: column !important;

        //     .routButton {
        //         width: 100%;

        //     }
        // }
    }
}