.mainSubsPlanTab {
    .innerSearchItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
    }

    .topSearch {
        position: relative;
        width: 460px;
        margin-right: 20px;

        .searchDoc {
            border: none;
            height: 40px;
            padding-left: 30px;

            &::placeholder {
                color: var(--inputtxtgreyColor8F9BBA);
                font-size: 14px;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .searchInner {
            position: absolute;
            top: 14px;
            left: 10px;
        }
    }

    .topFilter {
        height: 40px;
        width: 40px;
        background-color: var(--whiteBackGroundColorfff);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;

        .dropdown-toggle::after {
            content: none !important;
        }

        .dropdown-item {
            color: #042486;
        }

        .btn {
            border: none !important;
        }

        .btn.show {
            border-color: transparent !important;

            &:focus {
                outline: none !important;
            }
        }
    }

    .fixedHeight {
        height: calc(100vh - 200px);

        thead {
            tr {
                th {
                    position: sticky;
                    top: 0px;
                    background-color: #fff;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 12px;
                }
            }
        }
    }
}

// Edit And Add SUbscription
.addEditSubsBox {
    .colSix {
        height: calc(100vh - 200px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
    }

    .formBox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .formLabel {
            color: #042486;
        }

        .form-select {
            border: 1px solid var(--greyLightTxtColorValueA3AED0);
            border-radius: 10px;
            height: 44px;
            width: 85%;
            color: var(--greyLightTxtColorValueA3AED0);

            &::placeholder {
                font-size: 14px;
                color: var(--greyLightTxtColorValueA3AED0);
            }

            &:focus {
                box-shadow: none !important;
            }
        }

        .form-control {
            border: 1px solid var(--greyLightTxtColorValueA3AED0);
            border-radius: 10px;
            height: 44px;
            width: 85%;

            option {
                color: var(--greyLightTxtColorValueA3AED0);
            }

            &::placeholder {
                font-size: 14px;
                color: var(--greyLightTxtColorValueA3AED0);
            }

            &:focus {
                box-shadow: none !important;
            }
        }

        .form-textArea {
            border: 1px solid var(--greyLightTxtColorValueA3AED0);
            border-radius: 10px;
            width: 85%;
            min-height: 80px;
            max-height: max-content;

            &::placeholder {
                font-size: 14px;
                color: var(--greyLightTxtColorValueA3AED0);
            }

            &:focus {
                outline: none;
                box-shadow: none !important;
            }
        }
    }

    .saveBox {
        text-align: end;

        .innerBtn {
            text-decoration: none;
            width: 224px;
            height: 40px;
            background-color: var(--darkBackgroundcolor4675F1);
            border: none;
            color: var(--whiteTxtColorfff);
            border-radius: 20px;
            font-weight: 600;
        }
    }
}


// Discount Styles
.addEditMain {
    margin: 60px;

    .cardRow {
        justify-content: center;
    }

    .discountCard {
        background-color: #fff;
        padding: 20px;
        border-radius: 12px;

        .submitBtn {
            height: 40px;
            width: 120px;
            border-radius: 12px;
            background-color: #042486;
            color: #fff;
            border: none;
        }

        .form-control {
            &:focus {
                box-shadow: none;
            }
        }

        .form-check-input {
            margin-right: 10px;

            &:checked {
                background-color: #042486 !important;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .checkHere {
            margin-top: 5px;
            font-size: 14px;
        }
    }

}