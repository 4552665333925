.pharmacyShop-wrapper {
  .innerSearchItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .leftFilterTop {
    display: flex;
  }

  .topSearch {
    position: relative;
    width: 500px;
    margin-right: 20px;

    .searchDoc {
      border: none;
      height: 40px;
      padding-left: 30px;

      &::placeholder {
        color: var(--inputtxtgreyColor8F9BBA);
        font-size: 14px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .searchInner {
      position: absolute;
      top: 14px;
      left: 10px;
    }
  }

  .topFilter {
    height: 40px;
    width: 40px;
    background-color: var(--whiteBackGroundColorfff);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .slideBelow {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }

  .doctorsDetailsCard {
    padding: 10px;
    background-color: var(--whiteBackGroundColorfff);
    border-radius: 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;

    .docInsideImg {
      margin-bottom: 10px;
      width: 100%;
      height: 190px;
      object-fit: contain;
    }

    .medicalbelowDetails {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      .headTxt {
        font-size: 14px;
        color: var(--navyBluetxtColor042486);
        font-weight: 500;
        border-right: 1px solid var(--borderBottomF6F6F6);
        padding-right: 5px;
        margin-right: 5px;
      }

      .starFlex {
        display: flex;
        align-items: center;
        margin-right: 5px;

        .star {
          height: 16px;
          width: 16px;
        }
      }
    }

    .clockDet {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .clock {
        height: 20px;
        width: 20px;
        margin-right: 10px;
      }

      .timeDet {
        font-size: 12px;
        color: var(--greyLightTxtColorValueA3AED0);
      }
    }

    .belowBtns {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .disable_img {
      position: absolute;
      left: 10px;
      top: 5px;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

// Pharmacy shop details styles
.pharmacyShop-wrapper {
  .mainDetailCard {
    background-color: var(--whiteBackGroundColorfff) !important;
    border-radius: 20px;
    height: calc(100% - 40px);
    margin-bottom: 40px;

    .topSection {
      padding: 20px;
      height: 80px;
      background-color: var(--darkBackgroundcolor4675F1);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 70px;

      .innerImg {
        position: relative;

        img {
          width: 116px;
          height: 116px;
          border-radius: 16px;
          // position: absolute;
        }

        .docDetTxt {
          display: flex;
          position: absolute;
          top: 70px;
          left: 130px;
          width: max-content;

          .docName {
            font-size: 18px;
            color: var(--navyBluetxtColor042486);
            margin-bottom: 0px;
          }

          .docDomain {
            font-size: 12px;
            color: var(--greyLightTxtColorValueA3AED0);
          }
        }
        .disable_img {
          margin-left: 20px;
          margin-top: -2px;
          img {
            width: 22px;
            height: 22px;
          }
        }
      }
    }

    // TABS SECTION
    .doctorTabs {
      padding: 20px;

      .nav-pills {
        width: 100%;
        background-color: var(--liteGreyTABSBgF2F5FF);
        border-radius: 10px;
        padding: 5px;
        margin-bottom: 20px;
      }

      .nav-item {
        width: 20%;
        text-align: center;
      }

      .nav-link {
        color: var(--navyBluetxtColor042486) !important;
      }

      .nav-link:hover {
        color: var(--darkBlueColor4675F1);
      }

      .nav-pills .nav-link.active {
        background-color: var(--darkBackgroundcolor4675F1);
        color: #fff !important;
        transition: all 0.5s;

        &:hover {
          color: #fff;
        }
      }

      .headTittle {
        border-bottom: 1px solid var(--borderBottomF6F6F6);
        margin-bottom: 20px;

        .heading {
          font-size: 18px;
          color: var(--navyBluetxtColor042486);
        }
      }

      .keyValueContent {
        margin-bottom: 20px;

        .Key {
          font-size: 14px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        ul {
          padding-left: 20px !important;

          li {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }
        }

        .imagesBunch {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          align-items: center;

          img {
            margin: 5px 5px 0px 0px;
          }
        }

        .value {
          font-size: 16px;
          color: var(--navyBluetxtColor042486);
        }
      }

      .teamsCard {
        background-color: var(--whiteBackGroundColorfff);
        padding: 10px;
        border-radius: 20px;

        img {
          // height: 127px;
          width: 100%;
          margin-bottom: 10px;
        }

        .detailsInner {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .NameDOc {
            font-size: 14px;
            color: var(--navyBluetxtColor042486);
            margin-bottom: 0px !important;
            font-weight: bold;
          }

          .DomainDoc {
            font-size: 12px;
            color: var(--greyLightTxtColorValueA3AED0);
          }
        }
      }

      .flexyDoctorsInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .cardMd25 {
          min-width: 18%;
          margin-bottom: 20px;
        }
      }

      .moreMt {
        margin-top: 20px;
      }

      .mainAppointments {
        .allApointmentsRow {
          border-bottom: 1px solid var(--borderBottomF6F6F6);
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-bottom: 10px;
          margin-bottom: 10px;

          .headTxt {
            font-size: 18px;
            color: var(--navyBluetxtColor042486);
            margin-bottom: 0px !important;
            // font-weight: 600;
          }

          .FilImg {
            height: 24px;
            width: 24px;
          }
        }
      }

      .heightScroller {
        height: 470px;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none !important;
        }
      }

      .bankDetailsCard {
        background-color: var(--whiteBackGroundColorfff);
        padding: 10px;
        border-radius: 20px;
        // height: 260px;
        margin-bottom: 10px;

        .heading {
          font-size: 18px;
          color: var(--navyBluetxtColor042486);
          border-bottom: 1px solid var(--borderBottomF6F6F6);
          margin-bottom: 20px;
        }

        .detailsInsider {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-bottom: 20px;

          .key {
            font-size: 14px;
            color: var(--greyLightTxtColorValueA3AED0);
            width: 50%;
          }

          .Value {
            font-size: 16px;
            color: var(--navyBluetxtColor042486);
            width: 50%;
          }
        }

        .graphs {
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-between;
          // align-items: center;
          .profitGraph {
            margin-bottom: 20px;
          }

          .profitGraph,
          .lossGraph {
            padding: 10px 15px;
            background-color: var(--liteGreyTABSBgF2F5FF);
            border-radius: 20px;
            width: 100%;

            .graph {
              text-align: center !important;

              .apexcharts-yaxis,
              .apexcharts-xaxis-texts-g,
              .apexcharts-xaxis,
              #SvgjsLine1432 {
                display: none !important;
              }
            }

            .InsideTxt {
              color: var(--navyBluetxtColor042486);
              font-size: 14px;
              font-weight: 600;
            }

            .numberDet {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .dollarAmnt {
                font-size: 30px;
                color: var(--darkBlackColor000);
              }

              .plusPercent {
                font-size: 14px;
                color: var(--greenTextColor34B53A);
              }

              .lossPercent {
                font-size: 14px;
                color: var(--dangerignalD0182E);
              }
            }
          }

          .lossGraph {
            padding: 10px 15px;
            background-color: var(--liteGreyTABSBgF2F5FF);
            border-radius: 20px;
            width: 100%;
          }
        }
      }

      .ratingBox {
        .totalReviewCard {
          padding: 10px;
          border-right: 1px solid var(--borderBottomF6F6F6);

          .headTxt {
            font-size: 16px;
            color: var(--darNavyTxtColor2B3674);
          }

          .amntTxt {
            font-size: 30px;
            font-weight: 600;
            color: var(--darNavyTxtColor2B3674);
            margin-bottom: 0px;
          }

          .detailTxt {
            font-size: 12px;
            color: var(--greyLightTxtColorValueA3AED0);
            font-family: "Edu SA Beginner", cursive !important;
          }
        }

        .innerStars {
          display: flex;

          p {
            margin-right: 18px;
          }

          img {
            margin-right: 5px;
          }
        }

        .ratingStars {
          padding: 10px;

          .starLevels {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            .starImg {
              margin-right: 5px;
              height: 12px !important;
              width: 12px !important;
            }

            .starAmnt {
              font-size: 12px;
              color: var(--navyBluetxtColor042486);
              margin-right: 10px;
            }

            .greenBar {
              width: 100%;

              .progress-bar {
                background-color: #05cd61;
                height: 8px;
                border-radius: 20px;
              }
            }

            .bottlegreenBar {
              width: 100%;

              .progress-bar {
                background-color: #bceb00;
                height: 8px;
                border-radius: 20px;
              }
            }

            .liteOrangeBar {
              width: 100%;

              .progress-bar {
                background-color: #ffd600;
                height: 8px;
                border-radius: 20px;
              }
            }

            .darkOrangeBar {
              width: 100%;

              .progress-bar {
                background-color: #ff8329;
                height: 8px;
                border-radius: 20px;
              }
            }

            .redBar {
              width: 100%;

              .progress-bar {
                background-color: #e81300;
                height: 8px;
                border-radius: 20px;
              }
            }
          }
        }

        .belowLine {
          width: 100% !important;
          border-bottom: 1px solid var(--borderBottomF6F6F6);
          margin-bottom: 20px;
        }

        .petDetailsCard {
          padding: 10px;
          border-right: 1px solid var(--borderBottomF6F6F6);
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
            height: 100px;
            width: 100px;
          }

          .cardDetails {
            display: flex;
            flex-direction: column;

            .headingTx {
              font-size: 16px;
              font-weight: 600;
              color: var(--navyBluetxtColor042486);
              margin-bottom: 15px;
            }

            .reviewTxt {
              color: var(--greyLightTxtColorValueA3AED0);
              font-size: 14px;

              span {
                color: var(--navyBluetxtColor042486) !important;
              }
            }

            .spendsTxt {
              color: var(--greyLightTxtColorValueA3AED0);
              font-size: 14px;

              span {
                color: var(--navyBluetxtColor042486) !important;
              }
            }
          }
        }

        .regularCheqCard {
          padding: 10px;

          .ratingDetailsHead {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            .nameAndDate {
              display: flex;
              align-items: center;

              .nameLab {
                font-size: 16px;
                font-weight: 600;
                color: var(--navyBluetxtColor042486);
                border-right: 1px solid var(--borderBottomF6F6F6);
                padding-right: 10px;
                margin-right: 10px;
              }

              .dateLab {
                font-size: 12px;
                color: var(--greyLightTxtColorValueA3AED0);
              }
            }

            .starRate {
              .starIcon {
                height: 16px;
                width: 16px;
                margin-right: 5px;
              }

              .rateNum {
                font-size: 12px;
              }
            }
          }

          .paraFlow {
            min-height: 90px;
            overflow-y: auto;

            .insideParaDet {
              font-size: 14px;
              color: var(--greyLightTxtColorValueA3AED0);
              text-align: justify;
            }
          }
        }
      }
    }
  }
}

// SHOPS INVENTORY STYLES
.shopsInventory-wrapper {
  .InventoryTabs {
    width: 100%;

    .nav-pills {
      border-bottom: 1px solid var(--greyLightTxtColorValueA3AED0);
      margin-bottom: 20px;
    }

    .nav-pills .nav-link.active {
      background-color: transparent;
      color: var(--navyBluetxtColor042486);
      border-bottom: 2px solid var(--navyBluetxtColor042486);
      border-radius: 0px;
    }

    .nav-pills .nav-link {
      color: var(--greyLightTxtColorValueA3AED0);
    }

    .searchBox {
      position: relative;
      margin-bottom: 20px;

      .searchImg {
        position: absolute;
        top: 14px;
        left: 10px;
      }

      .searchDoc {
        border: none;
        height: 40px;
        padding-left: 30px;

        &::placeholder {
          color: var(--inputtxtgreyColor8F9BBA);
          font-size: 14px;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .selectBox {
      .form-select {
        border: none;
        height: 40px;
        color: var(--inputtxtgreyColor8F9BBA);
        font-size: 14px;
        background-image: url("../images/icons/selectIcon.svg");
        background-size: 10px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .addBox {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background-color: var(--whiteBackGroundColorfff);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .topFilter {
      height: 40px;
      width: 40px;
      background-color: var(--whiteBackGroundColorfff);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }

    .slideBelow {
      max-height: calc(100vh - 250px);
      overflow-y: auto;
    }

    .productCards {
      position: relative;
      background-color: var(--whiteBackGroundColorfff);
      border-radius: 20px;
      padding: 10px;
      margin-bottom: 20px;

      .cardImg {
        margin-bottom: 12px;
        width: 185px;
        height: 155px;
        border-radius: 20px;
      }

      .starFlex {
        position: absolute;
        top: 15px;
        right: 15px;
        display: flex;
        align-items: center;

        .innerStar {
          margin-right: 5px;
          height: 16px;
          width: 16px;
        }

        .innerRate {
          font-size: 12px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }
    }

    .cardDetails {
      margin-bottom: 15px;

      .productName {
        font-size: 14px;
        color: var(--darkBlueColor4675F1);
        margin-bottom: 0px;
      }

      p {
        font-size: 12px;
        color: var(--darkBlueColor4675F1);
        margin-bottom: 5px;
      }

      .paraTXt {
        margin-bottom: 10px;
        margin-top: 5px;
        color: var(--greyLightTxtColorValueA3AED0);
        text-align: center;
        height: 120px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    .quantityAnPrice {
      .quant {
        p {
          color: var(--darkBlueColor4675F1);
          font-size: 14px;
          // padding-right: 30px;
          margin-bottom: 0px;
        }

        .greyTxt {
          color: var(--greyLightTxtColorValueA3AED0);
          font-size: 14px;
        }

        .Amnt {
          color: var(--darkBlueColor4675F1);
          font-size: 14px;
          // margin-left: 10px;
        }
      }

      .price {
        border-left: 1px solid var(--greyLightTxtColorValueA3AED0);

        p {
          color: var(--darkBlueColor4675F1);
          font-size: 14px;
          // padding-right: 30px;
          margin-bottom: 0px;
        }

        .greyTxt {
          color: var(--greyLightTxtColorValueA3AED0);
          font-size: 14px;
        }

        .Amnt {
          // margin-left: 10px;
          color: var(--darkBlueColor4675F1);
          font-size: 14px;
        }
      }
    }
  }
}

// ADD PHARMACY SHOPS
.addPharmacy-wrapper {
  .innerSearchItem {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  .leftFilterTop {
    display: flex;
  }

  .topSearch {
    position: relative;
    width: 500px;
    margin-right: 20px;

    .searchDoc {
      border: none;
      height: 40px;
      padding-left: 30px;

      &::placeholder {
        color: var(--inputtxtgreyColor8F9BBA);
        font-size: 14px;
      }

      &:focus {
        box-shadow: none;
      }
    }

    .searchInner {
      position: absolute;
      top: 14px;
      left: 10px;
    }
  }

  .topFilter {
    height: 40px;
    width: 40px;
    background-color: var(--whiteBackGroundColorfff);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }

  .slideBelow {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }

  .cardLink {
    text-decoration: none !important;

    .doctorsDetailsCard {
      padding: 10px;
      background-color: var(--whiteBackGroundColorfff);
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;

      .docInsideImg {
        margin-bottom: 10px;
        height: 190px;
        width: 236px;
        border-radius: 20px;
      }

      .docDetailsTxt {
        text-align: center;

        h1 {
          color: var(--navyBluetxtColor042486);
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 0px !important;
        }

        label {
          font-size: 12px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }

      .innerButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }
}

// View Pharmacy styles
.viewPharmacy-wrapper {
  .mainDetailCard {
    background-color: var(--whiteBackGroundColorfff) !important;
    border-radius: 20px;
    height: calc(100% - 40px);
    margin-bottom: 40px;

    .topSection {
      padding: 20px;
      height: 80px;
      background-color: var(--darkBackgroundcolor4675F1);
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 70px;

      .innerImg {
        position: relative;

        .docImg {
          height: 120px;
          width: 120px;
        }

        // img{
        //     position: absolute;
        // }
        .docDetTxt {
          position: absolute;
          top: 70px;
          left: 130px;
          min-width: 200px;

          .docName {
            font-size: 18px;
            color: var(--navyBluetxtColor042486);
            margin-bottom: 0px;
          }

          .docDomain {
            font-size: 12px;
            color: var(--greyLightTxtColorValueA3AED0);
          }
        }
      }
    }

    .belowDetails {
      padding: 20px;

      .headTittle {
        border-bottom: 1px solid var(--borderBottomF6F6F6);
        margin-bottom: 20px;

        .heading {
          font-size: 18px;
          color: var(--navyBluetxtColor042486);
        }
      }

      .moreMt {
        margin-top: 20px;
      }

      .keyValueContent {
        margin-bottom: 20px;

        .Key {
          font-size: 14px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        ul {
          padding-left: 20px !important;

          li {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }
        }

        .imagesBunch {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          align-items: center;

          img {
            margin: 5px 5px 0px 0px;
          }
        }

        .value {
          font-size: 16px;
          color: var(--navyBluetxtColor042486);
        }
      }
    }
  }
}

// Master Inventory product list tabs styles
.masterProductList-wrapper {
  .MasterInvenTabs {
    width: 100%;

    .nav-pills {
      border-bottom: 1px solid var(--greyLightTxtColorValueA3AED0);
      margin-bottom: 20px;
    }

    .nav-pills .nav-link.active {
      background-color: transparent;
      color: var(--navyBluetxtColor042486);
      border-bottom: 2px solid var(--navyBluetxtColor042486);
      border-radius: 0px;
    }

    .nav-pills .nav-link {
      color: var(--greyLightTxtColorValueA3AED0);
    }

    .searchBox {
      position: relative;
      margin-bottom: 20px;

      .searchImg {
        position: absolute;
        top: 14px;
        left: 10px;
      }

      .searchDoc {
        border: none;
        height: 40px;
        padding-left: 30px;

        &::placeholder {
          color: var(--inputtxtgreyColor8F9BBA);
          font-size: 14px;
        }

        &:focus {
          box-shadow: none;
        }
      }
    }

    .selectBox {
      .form-select {
        border: none;
        height: 40px;
        color: var(--inputtxtgreyColor8F9BBA);
        font-size: 14px;
        background-image: url("../images/icons/selectIcon.svg");
        background-size: 10px;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .topFilter {
      height: 40px;
      width: 40px;
      background-color: var(--whiteBackGroundColorfff);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
    }

    .addBox {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      background-color: var(--whiteBackGroundColorfff);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .slideBelow {
      max-height: calc(100vh - 250px);
      overflow-y: auto;
    }

    .productCards {
      position: relative;
      background-color: var(--whiteBackGroundColorfff);
      border-radius: 20px;
      padding: 10px;
      margin-bottom: 20px;

      .cardImg {
        margin-bottom: 12px;
        width: fit-content;
        height: 100px;
        object-fit: cover;
      }

      .starFlex {
        position: absolute;
        top: 15px;
        right: 15px;
        display: flex;
        align-items: center;

        .innerStar {
          margin-right: 5px;
          height: 16px;
          width: 16px;
        }

        .innerRate {
          font-size: 12px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }
    }

    .switchFlex {
      display: flex;
      justify-content: space-between;
      // align-items: center;
    }

    .cardDetails {
      margin-bottom: 15px;

      .productName {
        font-size: 14px;
        color: var(--darkBlueColor4675F1);
        margin-bottom: 0px;
      }

      p {
        font-size: 12px;
        color: var(--darkBlueColor4675F1);
        margin-bottom: 5px;
      }
    }

    .paraTXt {
      margin-bottom: 10px;
      margin-top: 5px;
      color: var(--greyLightTxtColorValueA3AED0);
      text-align: center;
      font-size: 12px;
      height: 50px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .quantityAnPrice {
      margin-bottom: 20px;

      .quant {
        p {
          color: var(--darkBlueColor4675F1);
          font-size: 14px;
          // padding-right: 30px;
          margin-bottom: 0px;
        }

        .greyTxt {
          color: var(--greyLightTxtColorValueA3AED0);
          font-size: 14px;
        }

        .Amnt {
          color: var(--darkBlueColor4675F1);
          font-size: 14px;
          // margin-left: 10px;
        }
      }

      .price {
        border-left: 1px solid var(--greyLightTxtColorValueA3AED0);

        p {
          color: var(--darkBlueColor4675F1);
          font-size: 14px;
          // padding-right: 30px;
          margin-bottom: 0px;
        }

        .greyTxt {
          color: var(--greyLightTxtColorValueA3AED0);
          font-size: 14px;
        }

        .Amnt {
          // margin-left: 10px;
          color: var(--darkBlueColor4675F1);
          font-size: 14px;
        }
      }
    }

    .cardBtmBtn {
      .innerBtn {
        width: 100%;
        text-decoration: none;
        // width: 224px;
        height: 40px;
        background-color: var(--darkBackgroundcolor4675F1);
        border: none;
        color: var(--whiteTxtColorfff);
        border-radius: 20px;
        font-weight: 600;
      }
    }
  }
}

//  Add Product Styles
.addProduct-wrapper,
.editProduct-wrapper {
  .addCardWraper {
    background-color: var(--whiteBackGroundColorfff);
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;

    .cardHeader {
      border-bottom: 1px solid var(--addEditBelowBorderE9F5FB);
      margin-bottom: 20px;

      .headTxt {
        font-size: 16px;
        color: var(--navyBluetxtColor042486);
        padding-bottom: 10px;
      }
    }

    .insideForm {
      margin-bottom: 20px;

      .inputLabel {
        font-size: 14px;
        color: var(--navyBluetxtColor042486);
        margin-bottom: 5px;
      }

      .form-control {
        border: 1px solid var(--greyLightTxtColorValueA3AED0);
        border-radius: 10px;
        height: 44px;

        &::placeholder {
          font-size: 14px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        &:focus {
          box-shadow: none !important;
        }
      }

      .form-select {
        background-image: url("../images/icons/selectArrow.svg") !important;
        background-size: 10px;
        font-size: 14px;
        color: var(--greyLightTxtColorValueA3AED0);
        border: 1px solid var(--greyLightTxtColorValueA3AED0);
        border-radius: 10px;
        height: 44px;

        &:focus {
          box-shadow: none !important;
        }
      }

      .selectFileBox {
        cursor: pointer;
        border: 2px dashed var(--greyLightTxtColorValueA3AED0);
        height: 100px;
        border-radius: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .fileImg {
          height: 30px;
          width: 30px;
        }

        .innerFIleTXt {
          color: var(--fileGreyColorADADAD);
          font-size: 12px;

          span {
            color: var(--navyBluetxtColor042486);
            font-weight: bold;
          }
        }
      }
    }

    .previewSection {
      .previewLabel {
        font-size: 14px;
        color: var(--navyBluetxtColor042486);
        margin-bottom: 5px;
      }

      .previewImg {
        border: 1px solid #e9f5fb;
        height: 100px;
        width: 100px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          height: 90px;
          width: 90px;
          object-fit: fill;
        }
      }
    }
  }
}

.modal-body {
  padding: 0px !important;
}

.modal-content {
  border-radius: 0px;
  width: 816px;
}

// order Modal Blue  styles
.orderModalInvoice {
  .modalHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 60px;

    .upperBlue {
      background: url("../images/icons/upperBlue.svg");
      height: 150px;
      width: 61.8%;

      .docName {
        position: absolute;
        top: 40px;
        left: 60px;
        color: var(--modalHeadTxt34528A);
        font-size: 40px;
        font-weight: 600;
      }

      .domainLab {
        position: absolute;
        top: 90px;
        left: 60px;
        font-size: 21px;
        color: var(--navyBluetxtColor042486);
      }

      .certificateLab {
        position: absolute;
        top: 155px;
        left: 60px;
        font-size: 15px;
        color: var(--navyBluetxtColor042486);
      }
    }

    .docSymbolBox {
      .docSymImg {
        height: 130px;
        width: 130px;
        position: absolute;
        top: 45px;
        right: 60px;
      }
    }
  }

  .modalBody {
    margin: 0px 60px;
    position: relative;

    .detailsFill {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;

      .labelTxt .keyQuestion {
        font-size: 16px;
        color: var(--greyLightTxtColorValueA3AED0);
      }

      .labelTxt {
        width: 18% !important;

        .widthFixed {
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }

      .form-control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid var(--borderBottomBluish34528A);
        border-radius: 0px !important;
        padding: 0px;
        padding-left: 10px;
        width: 100%;
        color: var(--navyBluetxtColor042486);

        &::placeholder {
          color: var(--navyBluetxtColor042486);
          font-size: 16px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .addressFlex {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 12px;

      .labelTxt {
        width: 12%;

        .keyQuestion {
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }

      .form-control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid var(--borderBottomBluish34528A);
        border-radius: 0px !important;
        padding: 0px;
        padding-left: 10px;
        width: 100%;
        color: var(--navyBluetxtColor042486);

        &::placeholder {
          color: var(--navyBluetxtColor042486);
          font-size: 16px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .dateNdAgeFlex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      width: 100%;

      .ageBox {
        display: flex;
        align-items: center;
        width: 40%;

        .ageLab {
          width: 15%;
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        .form-control {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid var(--borderBottomBluish34528A);
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          color: var(--navyBluetxtColor042486);

          &::placeholder {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .dateBox {
        display: flex;
        align-items: center;
        width: 55%;

        .ageLab {
          width: 15%;
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        .form-control {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid var(--borderBottomBluish34528A);
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          color: var(--navyBluetxtColor042486);

          &::placeholder {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }

    .tableAndLandingBg {
      margin-top: 50px;
      position: relative;
      margin-bottom: 200px;

      .backGroundPic {
        margin-top: 78px;
        position: absolute;
        right: 0px;
        left: 0px;
        background: url("../images/icons/BackgroundInvoice.svg") no-repeat
          bottom scroll;
        background-size: contain;
        height: 380px;
        min-width: 500px;
      }

      .innerTable {
        margin-bottom: 100px;
        border: 1px solid var(--greyLightTxtColorValueA3AED0);
        width: 100%;
        border-radius: 20px;

        .modalTable {
          width: 100%;

          thead {
            border-bottom: 1px solid var(--greyLightTxtColorValueA3AED0);
            width: 100%;

            tr {
              th {
                font-size: 14px;
                color: var(--modalHeadTxt34528A);
                padding: 20px;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 14px;
                color: var(--modalHeadTxt34528A);
                padding: 10px 20px;
              }
            }
          }
        }
      }
    }

    .signatureContent {
      display: flex;
      justify-content: end;
      margin-bottom: 40px;

      .sigBox {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        .signaturePad {
          border-bottom: 1px solid var(--modalHeadTxt34528A);
          height: 100px;
          width: 215px;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .belowTxt {
          font-size: 16px;
          color: var(--navyBluetxtColor042486);
        }
      }
    }
  }

  .belowDetails {
    .imgWrapper {
      background: url("../images/icons/bottomBlue.svg");
      width: 100%;
      height: 100px;

      .heightAuto {
        height: inherit;
      }

      .txt {
        border-right: 2px solid var(--modalHeadTxt34528A);
        text-align: center;
        padding: 15px;

        .innerTxt {
          font-size: 20px;
          color: var(--modalHeadTxt34528A);
          padding-right: 20px;
        }
      }

      .allCOntact {
        padding: 20px 20px 20px 30px;

        .emailAndCall {
          display: flex;
          align-items: center;

          .callNnumber,
          .emialInn {
            display: flex;
            align-items: center;
            margin-right: 10px;
            margin-bottom: 20px;

            img {
              margin-right: 5px;
            }

            .numberInn {
              font-size: 16px;
              color: var(--modalHeadTxt34528A);
            }
          }
        }

        .locationInn {
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }

          .locationTxt {
            font-size: 16px;
            color: var(--modalHeadTxt34528A);
          }
        }
      }
    }
  }
}

.TransactionModalInvoice {
  .modalHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 60px;

    .upperBlue {
      background: url("../images/icons/upperGreen.svg");
      height: 150px;
      width: 61.8%;

      .docName {
        position: absolute;
        top: 40px;
        left: 60px;
        color: #21c896;
        font-size: 40px;
        font-weight: 600;
      }

      .domainLab {
        position: absolute;
        top: 90px;
        left: 60px;
        font-size: 21px;
        color: var(--navyBluetxtColor042486);
      }

      .certificateLab {
        position: absolute;
        top: 155px;
        left: 60px;
        font-size: 15px;
        color: var(--navyBluetxtColor042486);
      }
    }

    .docSymbolBox {
      .docSymImg {
        height: 130px;
        width: 130px;
        position: absolute;
        top: 45px;
        right: 60px;
      }
    }
  }

  .modalBody {
    margin: 0px 60px;
    position: relative;

    .detailsFill {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;

      .labelTxt .keyQuestion {
        font-size: 16px;
        color: var(--greyLightTxtColorValueA3AED0);
      }

      .labelTxt {
        width: 18% !important;

        .widthFixed {
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }

      .form-control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #21c896;
        border-radius: 0px !important;
        padding: 0px;
        padding-left: 10px;
        width: 100%;
        color: var(--navyBluetxtColor042486);

        &::placeholder {
          color: var(--navyBluetxtColor042486);
          font-size: 16px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .addressFlex {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 12px;

      .labelTxt {
        width: 12%;

        .keyQuestion {
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }

      .form-control {
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #21c896;
        border-radius: 0px !important;
        padding: 0px;
        padding-left: 10px;
        width: 100%;
        color: var(--navyBluetxtColor042486);

        &::placeholder {
          color: var(--navyBluetxtColor042486);
          font-size: 16px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .dateNdAgeFlex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      width: 100%;

      .ageBox {
        display: flex;
        align-items: center;
        width: 40%;

        .ageLab {
          width: 15%;
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        .form-control {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #21c896;
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          color: var(--navyBluetxtColor042486);

          &::placeholder {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .dateBox {
        display: flex;
        align-items: center;
        width: 55%;

        .ageLab {
          width: 15%;
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        .form-control {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid #21c896;
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          color: var(--navyBluetxtColor042486);

          &::placeholder {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }

    .tableAndLandingBg {
      margin-top: 50px;
      position: relative;
      margin-bottom: 200px;

      .backGroundPic {
        margin-top: 78px;
        position: absolute;
        right: 0px;
        left: 0px;
        background: url("../images/icons/BackgroundInvoice.svg") no-repeat
          bottom scroll;
        background-size: contain;
        height: 380px;
        min-width: 500px;
      }

      .InvoiceTxt {
        color: var(--navyBluetxtColor042486);
        margin-bottom: 10px;
      }

      .innerTable {
        margin-bottom: 20px;
        border: 1px solid var(--greyLightTxtColorValueA3AED0);
        width: 100%;
        border-radius: 20px;

        .modalTable {
          width: 100%;

          thead {
            border-bottom: 1px solid var(--greyLightTxtColorValueA3AED0);
            width: 100%;

            tr {
              th {
                font-size: 14px;
                color: var(--modalHeadTxt34528A);
                padding: 20px;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 14px;
                color: var(--modalHeadTxt34528A);
                padding: 10px 20px;
              }
            }
          }
        }
      }

      .belowTableDetails {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .paymentTxt {
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          .paymntTxtHead {
            color: var(--navyBluetxtColor042486);
            font-weight: 550;
            margin-bottom: 10px;
          }

          .paymntOption {
            color: var(--navyBluetxtColor042486);
          }
        }

        .rightTable {
          .tableInside {
            width: 310px;
            padding: 10px 20px;
            border: 1px solid var(--greyLightTxtColorValueA3AED0);
            border-radius: 20px;

            .detailFlexy {
              display: flex;
              margin-bottom: 10px;

              .insideLab,
              .insideLabTxt {
                width: 49%;
                color: var(--navyBluetxtColor042486);
                font-size: 14px;
              }
            }

            .totalSec {
              border-top: 1px solid var(--greyLightTxtColorValueA3AED0);
              padding-top: 10px;

              .detailFlexy {
                display: flex;
                margin-bottom: 10px;

                .insideLab,
                .insideLabTxt {
                  width: 49%;
                  color: var(--navyBluetxtColor042486);
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }

    .signatureContent {
      display: flex;
      justify-content: end;
      margin-bottom: 40px;

      .sigBox {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        .signaturePad {
          border-bottom: 1px solid var(--modalHeadTxt34528A);
          height: 100px;
          width: 215px;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .belowTxt {
          font-size: 16px;
          color: var(--navyBluetxtColor042486);
        }
      }
    }
  }

  .belowDetails {
    .imgWrapper {
      background: url("../images/icons/bottomGreen.svg");
      width: 100%;
      height: 100px;

      .heightAuto {
        height: inherit;
      }

      .txt {
        border-right: 2px solid #05cd61;
        text-align: center;
        padding: 15px;

        .innerTxt {
          font-size: 20px;
          color: #05cd61;
          padding-right: 20px;
        }
      }

      .allCOntact {
        padding: 20px 20px 20px 30px;

        .emailAndCall {
          display: flex;
          align-items: center;

          .callNnumber,
          .emialInn {
            display: flex;
            align-items: center;
            margin-right: 10px;
            margin-bottom: 20px;

            img {
              margin-right: 5px;
            }

            .numberInn {
              font-size: 16px;
              color: #05cd61;
            }
          }
        }

        .locationInn {
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }

          .locationTxt {
            font-size: 16px;
            color: #05cd61;
          }
        }
      }
    }
  }
}

.petModalInvoice {
  .modalHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 60px;

    .upperBlue {
      background: url("../images/icons/topPurple.svg");
      height: 150px;
      width: 61.8%;

      .docName {
        position: absolute;
        top: 40px;
        left: 60px;
        color: #000842;
        font-size: 40px;
        font-weight: 600;
      }

      .domainLab {
        position: absolute;
        top: 90px;
        left: 60px;
        font-size: 21px;
        color: var(--navyBluetxtColor042486);
      }

      .certificateLab {
        position: absolute;
        top: 155px;
        left: 60px;
        font-size: 15px;
        color: #182a33;
      }
    }

    .docSymbolBox {
      .docSymImg {
        height: 130px;
        width: 130px;
        position: absolute;
        top: 45px;
        right: 60px;
      }
    }
  }

  .modalBody {
    margin: 0px 60px;
    position: relative;

    .detailsFill {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;

      .labelTxt .keyQuestion {
        font-size: 16px;
        color: var(--greyLightTxtColorValueA3AED0);
      }

      .labelTxt {
        width: 18% !important;

        .widthFixed {
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }

      .form-control {
        border: none;
        border-bottom: 1px solid var(--borderBottomBluish34528A);
        border-radius: 0px !important;
        padding: 0px;
        padding-left: 10px;
        width: 100%;
        color: var(--navyBluetxtColor042486);
        background-color: transparent;

        &::placeholder {
          color: var(--navyBluetxtColor042486);
          font-size: 16px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .addressFlex {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 12px;

      .labelTxt {
        width: 12%;

        .keyQuestion {
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }

      .form-control {
        border: none;
        background-color: transparent;
        border-bottom: 1px solid var(--borderBottomBluish34528A);
        border-radius: 0px !important;
        padding: 0px;
        padding-left: 10px;
        width: 100%;
        color: var(--navyBluetxtColor042486);

        &::placeholder {
          color: var(--navyBluetxtColor042486);
          font-size: 16px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .dateNdAgeFlex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      width: 100%;

      .ageBox {
        display: flex;
        align-items: center;
        width: 40%;

        .ageLab {
          width: 15%;
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        .form-control {
          border: none;
          border-bottom: 1px solid var(--borderBottomBluish34528A);
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          color: var(--navyBluetxtColor042486);

          &::placeholder {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .dateBox {
        display: flex;
        align-items: center;
        width: 55%;

        .ageLab {
          width: 15%;
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        .form-control {
          background-color: transparent;
          border: none;
          border-bottom: 1px solid var(--borderBottomBluish34528A);
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          color: var(--navyBluetxtColor042486);

          &::placeholder {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }

    .tableAndLandingBg {
      margin-top: 50px;
      position: relative;
      margin-bottom: 200px;

      .backGroundPic {
        margin-top: 78px;
        position: absolute;
        right: 0px;
        left: 0px;
        background: url("../images/icons/BackgroundInvoice.svg") no-repeat
          bottom scroll;
        background-size: contain;
        height: 380px;
        min-width: 500px;
      }

      .InvoiceTxt {
        color: var(--navyBluetxtColor042486);
        margin-bottom: 10px;
      }

      .innerTable {
        margin-bottom: 20px;
        border: 1px solid var(--greyLightTxtColorValueA3AED0);
        width: 100%;
        border-radius: 20px;

        .modalTable {
          width: 100%;

          thead {
            border-bottom: 1px solid var(--greyLightTxtColorValueA3AED0);
            width: 100%;

            tr {
              th {
                font-size: 14px;
                color: var(--modalHeadTxt34528A);
                padding: 20px;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 14px;
                color: var(--modalHeadTxt34528A);
                padding: 10px 20px;
              }
            }
          }
        }
      }

      .belowTableDetails {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .paymentTxt {
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          .paymntTxtHead {
            color: var(--navyBluetxtColor042486);
            font-weight: 550;
            margin-bottom: 10px;
          }

          .paymntOption {
            color: var(--navyBluetxtColor042486);
          }
        }

        .rightTable {
          .tableInside {
            width: 310px;
            padding: 10px 20px;
            border: 1px solid var(--greyLightTxtColorValueA3AED0);
            border-radius: 20px;

            .detailFlexy {
              display: flex;
              margin-bottom: 10px;

              .insideLab,
              .insideLabTxt {
                width: 49%;
                color: var(--navyBluetxtColor042486);
                font-size: 14px;
              }
            }

            .totalSec {
              border-top: 1px solid var(--greyLightTxtColorValueA3AED0);
              padding-top: 10px;

              .detailFlexy {
                display: flex;
                margin-bottom: 10px;

                .insideLab,
                .insideLabTxt {
                  width: 49%;
                  color: var(--navyBluetxtColor042486);
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .belowTableDetails {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .paymentTxt {
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          .paymntTxtHead {
            color: var(--navyBluetxtColor042486);
            font-weight: 550;
            margin-bottom: 10px;
          }

          .paymntOption {
            color: var(--navyBluetxtColor042486);
          }
        }

        .rightTable {
          .tableInside {
            width: 310px;
            padding: 10px 20px;
            border: 1px solid var(--greyLightTxtColorValueA3AED0);
            border-radius: 20px;

            .detailFlexy {
              display: flex;
              margin-bottom: 10px;

              .insideLab,
              .insideLabTxt {
                width: 49%;
                color: var(--navyBluetxtColor042486);
                font-size: 14px;
              }
            }

            .totalSec {
              border-top: 1px solid var(--greyLightTxtColorValueA3AED0);
              padding-top: 10px;

              .detailFlexy {
                display: flex;
                margin-bottom: 10px;

                .insideLab,
                .insideLabTxt {
                  width: 49%;
                  color: var(--navyBluetxtColor042486);
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }

    .signatureContent {
      display: flex;
      justify-content: end;
      margin-bottom: 40px;

      .sigBox {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        .signaturePad {
          border-bottom: 1px solid var(--modalHeadTxt34528A);
          height: 100px;
          width: 215px;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .belowTxt {
          font-size: 16px;
          color: var(--navyBluetxtColor042486);
        }
      }
    }
  }

  .belowDetails {
    .imgWrapper {
      background: url("../images/icons/bottomPurple.svg");
      width: 100%;
      height: 100px;

      .heightAuto {
        height: inherit;
      }

      .txt {
        border-right: 2px solid #000842;
        text-align: center;
        padding: 15px;

        .innerTxt {
          font-size: 20px;
          color: #000842;
          padding-right: 20px;
        }
      }

      .allCOntact {
        padding: 20px 20px 20px 30px;

        .emailAndCall {
          display: flex;
          align-items: center;

          .callNnumber,
          .emialInn {
            display: flex;
            align-items: center;
            margin-right: 10px;
            margin-bottom: 20px;

            img {
              margin-right: 5px;
            }

            .numberInn {
              font-size: 16px;
              color: #000842;
            }
          }
        }

        .locationInn {
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }

          .locationTxt {
            font-size: 16px;
            color: #000842;
          }
        }
      }
    }
  }
}

.vaccinatorOrderModalInvoice {
  .modalHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 60px;

    .upperBlue {
      background: url("../images/icons/topPink.svg");
      height: 150px;
      width: 61.8%;

      .docName {
        position: absolute;
        top: 40px;
        left: 60px;
        color: #5b2868;
        font-size: 40px;
        font-weight: 600;
      }

      .domainLab {
        position: absolute;
        top: 90px;
        left: 60px;
        font-size: 21px;
        color: var(--navyBluetxtColor042486);
      }

      .certificateLab {
        position: absolute;
        top: 155px;
        left: 60px;
        font-size: 15px;
        color: var(--navyBluetxtColor042486);
      }
    }

    .docSymbolBox {
      .docSymImg {
        height: 130px;
        width: 130px;
        position: absolute;
        top: 45px;
        right: 60px;
      }
    }
  }

  .modalBody {
    margin: 0px 60px;
    position: relative;

    .detailsFill {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;

      .labelTxt .keyQuestion {
        font-size: 16px;
        color: var(--greyLightTxtColorValueA3AED0);
      }

      .labelTxt {
        width: 18% !important;

        .widthFixed {
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }

      .form-control {
        border: none;
        border-bottom: 1px solid #5b2868;
        border-radius: 0px !important;
        padding: 0px;
        padding-left: 10px;
        width: 100%;
        color: var(--navyBluetxtColor042486);

        &::placeholder {
          color: var(--navyBluetxtColor042486);
          font-size: 16px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .addressFlex {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 12px;

      .labelTxt {
        width: 12%;

        .keyQuestion {
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }

      .form-control {
        border: none;
        border-bottom: 1px solid #5b2868;
        border-radius: 0px !important;
        padding: 0px;
        padding-left: 10px;
        width: 100%;
        color: var(--navyBluetxtColor042486);

        &::placeholder {
          color: var(--navyBluetxtColor042486);
          font-size: 16px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .dateNdAgeFlex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      width: 100%;

      .ageBox {
        display: flex;
        align-items: center;
        width: 40%;

        .ageLab {
          width: 15%;
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        .form-control {
          border: none;
          border-bottom: 1px solid #5b2868;
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          color: var(--navyBluetxtColor042486);

          &::placeholder {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .dateBox {
        display: flex;
        align-items: center;
        width: 55%;

        .ageLab {
          width: 15%;
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        .form-control {
          border: none;
          border-bottom: 1px solid #5b2868;
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          color: var(--navyBluetxtColor042486);

          &::placeholder {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }

    .tableAndLandingBg {
      margin-top: 50px;
      position: relative;
      margin-bottom: 200px;

      .backGroundPic {
        margin-top: 65px;
        position: absolute;
        right: 0px;
        left: 0px;
        background: url("../images/icons/BackgroundInvoice.svg") no-repeat
          bottom scroll;
        background-size: contain;
        height: 380px;
        min-width: 500px;
      }

      .innerTable {
        margin-bottom: 300px;
        border: 1px solid var(--greyLightTxtColorValueA3AED0);
        width: 100%;
        border-radius: 20px;

        .modalTable {
          width: 100%;

          thead {
            border-bottom: 1px solid var(--greyLightTxtColorValueA3AED0);
            width: 100%;

            tr {
              th {
                font-size: 14px;
                color: #5b2868;
                padding: 20px;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 14px;
                color: #5b2868;
                padding: 10px 20px;
              }
            }
          }
        }
      }
    }

    .signatureContent {
      display: flex;
      justify-content: end;
      margin-bottom: 40px;

      .sigBox {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        .signaturePad {
          border-bottom: 1px solid var(--modalHeadTxt34528A);
          height: 100px;
          width: 215px;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .belowTxt {
          font-size: 16px;
          color: var(--navyBluetxtColor042486);
        }
      }
    }
  }

  .belowDetails {
    .imgWrapper {
      background: url("../images/icons/bottomPink.svg");
      width: 100%;
      height: 100px;

      .heightAuto {
        height: inherit;
      }

      .txt {
        border-right: 2px solid #5b2868;
        text-align: center;
        padding: 15px;

        .innerTxt {
          font-size: 20px;
          color: #5b2868;
          padding-right: 20px;
        }
      }

      .allCOntact {
        padding: 20px 20px 20px 30px;

        .emailAndCall {
          display: flex;
          align-items: center;

          .callNnumber,
          .emialInn {
            display: flex;
            align-items: center;
            margin-right: 10px;
            margin-bottom: 20px;

            img {
              margin-right: 5px;
            }

            .numberInn {
              font-size: 16px;
              color: #5b2868;
            }
          }
        }

        .locationInn {
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }

          .locationTxt {
            font-size: 16px;
            color: #5b2868;
          }
        }
      }
    }
  }
}

.TransactionPetModalInvoice {
  .modalHeading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 60px;

    .upperBlue {
      background: url("../images/icons/topPink.svg");
      height: 150px;
      width: 61.8%;

      .docName {
        position: absolute;
        top: 40px;
        left: 60px;
        color: #5b2868;
        font-size: 40px;
        font-weight: 600;
      }

      .domainLab {
        position: absolute;
        top: 90px;
        left: 60px;
        font-size: 21px;
        color: var(--navyBluetxtColor042486);
      }

      .certificateLab {
        position: absolute;
        top: 155px;
        left: 60px;
        font-size: 15px;
        color: var(--navyBluetxtColor042486);
      }
    }

    .docSymbolBox {
      .docSymImg {
        height: 130px;
        width: 130px;
        position: absolute;
        top: 45px;
        right: 60px;
      }
    }
  }

  .modalBody {
    margin: 0px 60px;
    position: relative;

    .detailsFill {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;

      .labelTxt .keyQuestion {
        font-size: 16px;
        color: var(--greyLightTxtColorValueA3AED0);
      }

      .labelTxt {
        width: 18% !important;

        .widthFixed {
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }

      .form-control {
        border: none;
        border-bottom: 1px solid #5b2868;
        border-radius: 0px !important;
        padding: 0px;
        padding-left: 10px;
        width: 100%;
        color: var(--navyBluetxtColor042486);

        &::placeholder {
          color: var(--navyBluetxtColor042486);
          font-size: 16px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .addressFlex {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 12px;

      .labelTxt {
        width: 12%;

        .keyQuestion {
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }
      }

      .form-control {
        border: none;
        border-bottom: 1px solid #5b2868;
        border-radius: 0px !important;
        padding: 0px;
        padding-left: 10px;
        width: 100%;
        color: var(--navyBluetxtColor042486);

        &::placeholder {
          color: var(--navyBluetxtColor042486);
          font-size: 16px;
        }

        &:focus {
          box-shadow: none !important;
        }
      }
    }

    .dateNdAgeFlex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      width: 100%;

      .ageBox {
        display: flex;
        align-items: center;
        width: 40%;

        .ageLab {
          width: 15%;
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        .form-control {
          border: none;
          border-bottom: 1px solid #5b2868;
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          color: var(--navyBluetxtColor042486);

          &::placeholder {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }

      .dateBox {
        display: flex;
        align-items: center;
        width: 55%;

        .ageLab {
          width: 15%;
          font-size: 16px;
          color: var(--greyLightTxtColorValueA3AED0);
        }

        .form-control {
          border: none;
          border-bottom: 1px solid #5b2868;
          border-radius: 0px !important;
          padding: 0px;
          padding-left: 10px;
          width: 100%;
          color: var(--navyBluetxtColor042486);

          &::placeholder {
            color: var(--navyBluetxtColor042486);
            font-size: 16px;
          }

          &:focus {
            box-shadow: none !important;
          }
        }
      }
    }

    .tableAndLandingBg {
      margin-top: 50px;
      position: relative;
      margin-bottom: 200px;

      .backGroundPic {
        margin-top: 65px;
        position: absolute;
        right: 0px;
        left: 0px;
        background: url("../images/icons/BackgroundInvoice.svg") no-repeat
          bottom scroll;
        background-size: contain;
        height: 380px;
        min-width: 500px;
      }

      .belowTableDetails {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .paymentTxt {
          margin-top: 10px;
          display: flex;
          flex-direction: column;

          .paymntTxtHead {
            color: #5b2868;
            font-weight: 550;
            margin-bottom: 10px;
          }

          .paymntOption {
            color: #5b2868;
          }
        }

        .rightTable {
          .tableInside {
            width: 310px;
            padding: 10px 20px;
            border: 1px solid #5b2868;
            border-radius: 20px;

            .detailFlexy {
              display: flex;
              margin-bottom: 10px;

              .insideLab,
              .insideLabTxt {
                width: 49%;
                color: #5b2868;
                font-size: 14px;
              }
            }

            .totalSec {
              border-top: 1px solid var(--greyLightTxtColorValueA3AED0);
              padding-top: 10px;

              .detailFlexy {
                display: flex;
                margin-bottom: 10px;

                .insideLab,
                .insideLabTxt {
                  width: 49%;
                  color: #5b2868;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .innerTable {
        margin-bottom: 20px;
        border: 1px solid var(--greyLightTxtColorValueA3AED0);
        width: 100%;
        border-radius: 20px;

        .modalTable {
          width: 100%;

          thead {
            border-bottom: 1px solid var(--greyLightTxtColorValueA3AED0);
            width: 100%;

            tr {
              th {
                font-size: 14px;
                color: #5b2868;
                padding: 20px;
              }
            }
          }

          tbody {
            tr {
              td {
                font-size: 14px;
                color: #5b2868;
                padding: 10px 20px;
              }
            }
          }
        }
      }
    }

    .signatureContent {
      display: flex;
      justify-content: end;
      margin-bottom: 40px;

      .sigBox {
        display: flex;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        .signaturePad {
          border-bottom: 1px solid var(--modalHeadTxt34528A);
          height: 100px;
          width: 215px;
          margin-bottom: 10px;
          cursor: pointer;
        }

        .belowTxt {
          font-size: 16px;
          color: var(--navyBluetxtColor042486);
        }
      }
    }
  }

  .belowDetails {
    .imgWrapper {
      background: url("../images/icons/bottomPink.svg");
      width: 100%;
      height: 100px;

      .heightAuto {
        height: inherit;
      }

      .txt {
        border-right: 2px solid #5b2868;
        text-align: center;
        padding: 15px;

        .innerTxt {
          font-size: 20px;
          color: #5b2868;
          padding-right: 20px;
        }
      }

      .allCOntact {
        padding: 20px 20px 20px 30px;

        .emailAndCall {
          display: flex;
          align-items: center;

          .callNnumber,
          .emialInn {
            display: flex;
            align-items: center;
            margin-right: 10px;
            margin-bottom: 20px;

            img {
              margin-right: 5px;
            }

            .numberInn {
              font-size: 16px;
              color: #5b2868;
            }
          }
        }

        .locationInn {
          display: flex;
          align-items: center;

          img {
            margin-right: 10px;
          }

          .locationTxt {
            font-size: 16px;
            color: #5b2868;
          }
        }
      }
    }
  }
}
