.background_main_wrapper {
  background-color: var(--lightBodyBackgroundEEF3FC);
}

body {
  --lightBodyBackgroundEEF3FC: #3377f7;
}

#cx-main {
  overflow-x: hidden;
  margin-left: 250px;
  margin-top: 30px;
  padding: 20px;
  transition: all .5s;
  min-height: calc(100vh - 80px);
}

.cx-active {
  margin-left: 60px !important;
}

// .scroll_div {
//   overflow-x: auto;
// }
// Global tables
.incedoTable {
  height: 500px;
  overflow-x: auto;

  .innerYscroll {
    height: 250px;
    overflow: auto;
  }

  .defaultTable {
    min-width: 800px;
    width: 100%;

    thead {
      tr {
        border-bottom: 1px solid var(--lightGreyborderColorEEF3FC);

        th {
          position: sticky;
          top: 0;
          background-color: #fff;
          padding-bottom: 10px;
          color: var(--greyLightTxtColorValueA3AED0);
          font-size: 12px;
        }

        .headTxt {
          margin-left: 10px;
        }
      }
    }

    .tableBody {
      tr {
        td {
          padding: 10px 10px 10px 0px;
          font-size: 12px;
          color: var(--navyBluetxtColor042486);
        }

        .insiderWidth {
          max-width: 80px !important;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .map-link {
          text-decoration: none;
          color: #34528A;
          font-size: 12px;
        }

        .map-lab {
          color: #34528A;
          font-size: 12px;
          cursor: pointer;
        }

        .tableImg {
          margin-right: 10px;
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }

        .checks {
          margin-right: 5px;
        }

        .diagnoseSignalsCheck {
          width: 80px;
          height: 24px;
          border-radius: 10px;
          background-color: var(--OrangeSignalTableFBA600);
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .noData {
          width: 80px;
          height: 24px;
          border-radius: 10px;
          background-color: red;
          opacity: .6;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .diagnoseSignalsVaccine {
          width: 80px;
          height: 24px;
          border-radius: 10px;
          background-color: var(--purpleSignalTableCD18D0);
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .diagnoseSignalsSurgery {
          width: 80px;
          height: 24px;
          border-radius: 10px;
          background-color: var(--successBgSignal18D0A4);
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .diagnoseSignalsTest {
          width: 80px;
          height: 24px;
          border-radius: 10px;
          background-color: var(--dangerignalD0182E);
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .clickableImg {
          cursor: pointer;
        }
      }
    }
  }
}

.innerToolTip .tooltip-inner {
  max-width: 500px;
  font-size: 10px;
}

.numberFlex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;

  .aboveDEtail,
  .belowDEtail {
    .key {
      color: #A3AED0;
      font-size: 10px;
    }

    .value {
      color: #fff;
      font-size: 10px;
      margin-left: 5px;
    }
  }
}

// Select customize
.formSelect {
  .form-select {
    background-image: url('../images/icons/selectDropD.svg') !important;
    border: none;
    color: var(--greyLightTxtColorValueA3AED0);
    font-size: 12px;
    background-size: 12px;

    &:focus {
      box-shadow: none !important;
    }
  }
}

// Default Button
.routButton {
  .innerBtn {
    text-decoration: none;
    width: 224px;
    height: 40px;
    background-color: var(--darkBackgroundcolor4675F1);
    border: none;
    color: var(--whiteTxtColorfff);
    border-radius: 20px;
    font-weight: 600;
  }
}

// Accept & Reject Button
.rejectBtn {
  border: 1px solid var(--darkBlueColor4675F1);
  height: 40px;
  width: 115px;
  border-radius: 20px;
  color: var(--darkBlueColor4675F1);
  background-color: transparent;
}

.AcceptBtn {
  border: 1px solid var(--darkBlueColor4675F1);
  height: 40px;
  width: 115px;
  border-radius: 20px;
  color: var(--whiteTxtColorfff);
  background-color: var(--darkBlueColor4675F1);
}

// DROP DOWN
.dropDown {
  .dropdown {
    button {
      background-color: white;
      border: none;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .btn-check:checked+.btn,
  .btn.active,
  .btn.show,
  .btn:first-child:active,
  :not(.btn-check)+.btn:active {
    background-color: white;
  }

  .dropdown-menu {
    inset: -16px 25px auto auto !important;
    border-radius: 20px;
    min-width: 100px;
    border: 1px solid #f6efe9;
    padding: 14px 0px;
  }

  .dropdown-item:focus,
  .dropdown-item:hover {
    background-color: white !important;
    // color: #8f4300 !important;
    color: var(--darNavyTxtColor2B3674) !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    cursor: pointer;
  }

  .btn:hover {
    color: none !important;
    background-color: #fff !important;

  }

  .dropdown-item {
    a {
      text-decoration: none !important;
      color: var(--greyLightTxtColorValueA3AED0) !important;
    }

    a:hover {
      color: var(--darNavyTxtColor2B3674) !important;
    }
  }

  .dropdown-item {
    font-size: 14px !important;
    font-weight: 400 !important;
    padding: 0px 15px 4px 15px !important;

    &:last-child {
      padding: 0px 15px 0px 15px !important;
      ;
    }
  }
}

// Switch styless
.form-switch .form-check-input {
  cursor: pointer;
  background-image: url('../images/icons/switchCircle.svg');
  background-size: 14px;
  background-color: var(--switchBackgroundColorE9F5FB);
  border: none;
  height: 16px;
  width: 30px;

  &:focus {
    box-shadow: none;
  }
}

.form-switch .form-check-input:checked {
  background-color: var(--darkBackgroundcolor4675F1);
  background-image: url('../images/icons/checkedCircle.svg');
  background-size: 14px;
  height: 16px;
  width: 30px;
}