.cx-sideBar {
    ::-webkit-scrollbar {
        display: none !important;
    }

    .cx-sidebar-wrapper {
        transition: all 0.3s;
        width: 250px;
        position: fixed;
        top: 0;
        z-index: 111;
        height: 100vh;
        background-color: var(--whiteBackGroundColorfff);

        .logoMain {
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid var(--lightGreyborderColorEEF3FC);
            // margin-bottom: 30px;
            a{
                img{
                    height: 70px;
                }
            }
        }

        .clickAbleArrow {
            padding: 20px;
            display: flex;
            justify-content: flex-end;

            img {
                display: none;
                cursor: pointer;
            }
        }

        // .main_menu {
        .overflowHeight {
            height: calc(100vh - 130px);
            overflow-y: auto;

            ::-webkit-scrollbar {
                display: none !important;
            }

        }

        .accordion-item {
            margin-bottom: 0px !important;
            border: none;
        }

        .accordion-button:not(.collapsed)::after {
            background-image: none !important;
        }

        .accordion-button:not(.collapsed) {
            box-shadow: none !important;
            background-color: transparent;
            padding: 0px !important;
        }

        .accordion-item:last-of-type {
            border: none;
        }

        .accordion-body {
            margin-top: 0px !important;
            padding: 0px 0px 0px 25px;
            margin-bottom: 15px;
        }

        .accordion-button {
            display: block;
            padding: 0px !important;
        }

        .accordion-item:last-of-type .accordion-button.collapsed {
            padding: 0px !important;
        }

        .accordion-button:focus {
            border: none !important;
            box-shadow: none !important;
        }

        .sidebarLinks {
            padding: 8px 0px 8px 20px;
            width: 100%;
            // margin-bottom: 20px;
        }

        .targetLink {
            text-decoration: none;
        }

        .dashboardLink {
            position: relative;
            display: flex;
            align-items: center;
            margin-bottom: 23px;
        }

        .arrowSetting {
            position: absolute;
            top: 0px;
            right: 20px;
            transition: all 0.5s;
        }

        .activeTxt {
            font-size: 16px;
            color: var(--navyBluetxtColor042486);
            font-weight: 600;
            margin-bottom: 0px !important;
            margin-left: 10px;
        }

        .deActiveTxt {
            font-size: 16px;
            color: var(--greyLightTxtColorValueA3AED0);
            font-weight: 400;
            margin-bottom: 0px !important;
            margin-left: 10px;
        }

        .activelogo {
            position: absolute;
            right: 0px;
        }


        .menu_items {
            transition: all 0.5s;
            border-left: 2px solid var(--greyLightTxtColorValueA3AED0);
            display: flex;
            flex-direction: column;

            .menuLink {
                margin-left: 25px;
                text-decoration: none;
                color: var(--greyLightTxtColorValueA3AED0);
                margin-bottom: 10px;
            }

            // }
        }

        // }

    }

    .sidebarActive {
        transition: all 0.3s;
        width: 60px;
        position: fixed;
        top: 0;
        z-index: 111;
        height: 100vh;
        background-color: var(--whiteBackGroundColorfff);

        .clickAbleArrow {
            // display: ;
            padding: 20px;
            text-align: end;

            .liteArrow {}

            .SideBarActiveArrow {
                transition: all 0.3s !important;
                transform: rotate(360deg) !important;
            }
        }

        .dashboardLink {
            p {
                display: none;
                transition: all 0.3s !important;
            }
        }
    }
}